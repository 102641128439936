/* @import "./config.js"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --bd-color: green;
  --text-color: black;
  --btn-primary-text: 'aqua';
  --btn-primary: 'aqua';
  --btn-primary-hover-text: 'aqua';
  --btn-primary-hover: 'brown';
  --btn-danger-text: 'aqua';
  --btn-danger: 'aqua';
  --btn-danger-hover-text: 'aqua';
  --btn-danger-hover: 'brown';
  --btn-info-text: 'aqua';
  --btn-info: 'aqua';
  --btn-info-hover-text: 'aqua';
  --btn-info-hover: 'brown';
  --nav-text-color: 'aqua';
  --nav-hover-text-color: 'brown';
}
