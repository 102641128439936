.react-tel-input {
    position: absolute !important;
}
.react-tel-input .form-control {
     width: inherit !important;
}
.table-filter select.form-control {
    width: 110px;
}

.filter-table {
    width: 80%;
    margin: 0 auto;
}

.filter-table .css-g1d714-ValueContainer {
    max-height: 101px;
    overflow: auto;
    padding: 2px 5px !important;
}

.filter-table .css-tlfecz-indicatorContainer {
    padding: 2px;
}

.filter-table .css-yk16xz-control {
    min-height: 33px;
}

.rounded-100 {
    border-radius: 100px;
    padding: 0 10px;
}

table#lead-datatable th,
table#lead-datatable td,
#layout-wrapper table.table.table-bordered th,
#layout-wrapper table.table.table-bordered td {
    vertical-align: middle;
    text-align: center;
}

#layout-wrapper table.table.table-bordered th {
/*    text-transform: uppercase;*/
}

.communication span.badge.rounded-100 {
    padding: 5px 8px;
    margin: 0 5px;
}

.border-3 {
    border: 3px solid #acabaf;
}

.btn-success.dark-success {
    background-color: #008037;
}

table#lead-datatable thead tr th,
#layout-wrapper table.table.table-bordered thead tr th {
    padding: 6px 1px;
    background: #d5d2d2;
    color: #000;
}
.vertical-menu{
    z-index: 9;
}
.vertical-menu-btn{
    background: #ede7e7;
}

table#lead-datatable,
#layout-wrapper table.table.table-bordered {
    border-collapse: separate;
    border-spacing: 0 10px;
}

table#lead-datatable th,
table#lead-datatable td,
#layout-wrapper table.table.table-bordered th,
#layout-wrapper table.table.table-bordered td {
    text-align: center;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin: 0 0 0 0;
}

#layout-wrapper table.table.table-bordered td {
    border-right: 0;
}

table#lead-datatable td:first-child,
#layout-wrapper table.table.table-bordered td:first-child {
    border-left: 2px solid #ddd;
}

table#lead-datatable td:last-child,
#layout-wrapper table.table.table-bordered td:last-child {
    border-right: 2px solid #ddd;
}

table#lead-datatable th,
#layout-wrapper table.table.table-bordered th {
    border-top: 0;
    border-bottom: 0;
}

.border-20.card-body {
    border: 3px solid #ddd;
}

#layout-wrapper table.table.table-bordered.table-striped {
    border: 0;
}

#layout-wrapper table.table.table-bordered.table-striped td:last-child {
    border-right: 2px solid #ddd;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #fff;
}

ul.popupSideBar {
    padding: 0;
    list-style: none;
    height: 100vh;
}

ul.popupSideBar li {
    background: #ebebeb;
    position: relative;
}

ul.popupSideBar li.mm-active,
ul.popupSideBar li.mm-active button.dropdown-item {
    background-color: #008037;
    color: #fff
}

ul.popupSideBar li.mm-active span,
ul.popupSideBar li.mm-active i {
    color: #fff
}

.NoShadow.HeightAuto.PopUpDataShow.card {
    border: 3px solid #ebebeb;
    border-radius: 5px;
    margin: 0
}

.PopUpProgress {
    width: 56%;
    float: right;
    border: 2px solid #222;
    border-radius: 36px;
    padding: 2px;
    margin: 0 0 20px 0;
}

.PopUpProgress .progress {
    float: right;
}

.PopUpProgress .progress {
    height: 30px;
    width: 100%
}

.PopUpModal .modal-body {
    height: calc(100vh - 120px);
}

.PopUpDataShow .NoShadow.traning.VideoHeight.card {
    height: calc(100vh - 212px);
}

ul.popupSideBar {
    padding: 0;
    list-style: none;
    height: calc(100vh - 180px);
}

.PopUpDataShow .NoShadow.traning.VideoHeight.card {
    height: calc(100vh - 222px);
    margin: 10px 0;
}

.PopUpDataShow .NoShadow.traning.VideoHeight.card .card-body,
.PopUpDataShow .video-lesson {
    height: 100%;
}

ul.popupSideBar li:hover,
ul.popupSideBar li button:hover {
    background-color: #008037;
    color: #fff
}

.PopUpModal .modal-footer {
    padding: 5px 12px;
    border: 0;
    position: relative;
    z-index: 1;
}

.PopUpModal .modal-content {
    border: 5px solid #dee2e6;
}

ul.popupSideBar li i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: flex;
    align-items: center;
}

ul.summary-report li img.assessment-icon {
    width: 45px;
    margin-top: 5px;
}

tbody#MainHTML td select.form-control {
    max-width: 70px;
}

.survey.card.card-body table.table tbody tr td.ImplimAssment {
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    padding: 0 10px 0 0px;
}

.show-value {
    position: relative;
    z-index: 1;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li {
    display: flex;
    justify-content: space-between;
    padding: 0 0px 0 10px;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .StatusIcon {
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul .ContactDescription {
    width: 94%;
    word-break: break-all;
    border-radius: 0 5px 5px 0;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 330px;
    overflow: auto;
    height: calc(100vh - 450px);
    margin-top: 10px;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .StatusIcon i.ion {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserActivityDetails.ProfileClientActivity .VariousCalls .RecentRecord.ProfileMessages ul li .MessageDate {
    margin: 0;
    font-size: 13px;
    float: left;
    line-height: normal;
    font-weight: 600;
}

.UserActivityDetails.ProfileClientActivity.card .tab-pane.secondary-tabs.profilePopup .card-body {
    min-height: auto;
    padding-bottom: 10px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-in {
    width: 100%;
    padding: 0;
    box-shadow: none;
}

.NewClientDialer ul li.Activitylis .ContactDescription {
    min-width: calc(100% + 50px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .desp-box {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    min-width: 174px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .ContactDescription {
    background: #051D40;
    float: left;
    width: auto;
    min-width: calc(100% - 30px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out .desp-box {
    /*max-width: 185px;*/
    min-width: calc(100% - 29px);
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-out {
    padding-left: 10px;
}

.NewClientDialer .InboxBusiness ul li.Activitylis.activity-note-in .desp-box {
    max-width: 195px;
    min-width: 195px;
}

.se-component.se-video-container.__se__float-none {
    height: 535px;
    margin-bottom: 20px;
}

.se-component.se-video-container.__se__float-none figure {
    height: 100% !important;
    padding-bottom: 0 !important;
}

.se-component.se-video-container.__se__float-none p {
    height: 100%;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask li {
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px 0 #999;
    width: 97%;
    margin: 5px auto 10px;
    padding: 0 10px;
    border-radius: 5px;
}

.NewCoachDashboard ul.CalendarTask li {
    box-shadow: 0 0 5px 0 #999;
    margin: 5px auto 10px;
    width: 97%;
    border-radius: 5px;
}

.ProfileClientActivity.Events .DayCal {
    margin-top: -30px;
    max-height: 237px;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: 185px;
    overflow: auto;
}

.send-message .form-group.mx-0.row {
    display: flex;
}

.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .ProfileBreakDown {
    overflow: unset;
}

.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .ProfileBreakDown .card-body {
    min-height: 300px;
}

.WayBookBar {
    background: #f0f4f8;
    position: fixed;
    top: 58px;
    width: 23.7%;
    bottom: 0;
    z-index: 1;
}

.WayBookBar .WayBookDropDown {
    background: #e7edf3;
}

.WayBookCate {
    background: transparent;
}

.WayBookCate .accordion-button::after {
    flex-shrink: 0;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
    left: 0;
    position: absolute;
    background-image: none;
    font-size: 16px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0da";
    height: auto;
    width: auto;
}

.WayBookCate .accordion-button.collapsed::after {
    transform: rotate(0deg);
}

.WayBookCate .accordion-button::after {
    transform: rotate(90deg);
}

.WayBookCate .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.WayBookCate .accordion-item {
    border: 0;
}

.WayBookCate .accordion-header button.accordion-button {
    padding-bottom: 5px;
    background: transparent;
}

.WayBookCate .subject-action {
    display: none;
}

.WayBookCate .ThreeDots:hover .subject-action {
    display: flex;
    width: 36px;
    align-items: center;
}

.WayBookCate .list-group-flush {
    border-radius: 5px;
}

.WayBookCate .document-action {
    display: none;
    position: absolute;
    right: 0;
    top: 10px;
    align-items: center;
}

.WayBookCate .document-action i.fas.fa-info-circle {
    margin: 0;
    line-height: normal;
    color: #f93;
}

.WayBookCate .list-group-flush>.list-group-item:hover .document-action {
    display: flex;
}

.WayBookCate .subject .active {
    background: transparent;
    min-height: 25px;
}

.WayBookCate .content-way.collapse.show .active.p-2.list-group-item {
    height: auto;
    background: #f8fafc;
    border-color: #f0f4f8;
}

.WayBookCate .content-way.collapse.show .active.p-2.list-group-item a {
    font-weight: 500;
    color: #102a43;
}

.WayBookCate .content-way.collapse.show .p-2.list-group-item a {
    color: #000;
    font-weight: normal;
}

.way-subject-header .modal-header {
    padding: 0.81rem;
}

.way-subject-header .modal-header h5 {
    color: #000;
}

.border-table {
    border: 1px solid #eee;
}

.border-table tbody tr.content-page-documents-list-item {

    vertical-align: middle;
    height: 65px;
}

.way-content {
    height: calc(100vh - 116px);
    position: relative;
    z-index: 0;
    overflow-x: hidden;

}

.way-content .gjs-pn-views {
    width: 20%;
    height: 40px;
}

.way-content .gjs-pn-options {
    right: 20%;
}

.way-content .gjs-cv-canvas {
    width: 78%;
}

.way-content .gjs-pn-views-container {
    width: 22%;
}

.page-content.waybook-content {
    /* padding: calc(40px + 24px) calc(0px / 2) 55px calc(0px / 2); */
    padding: calc(30px + 8px) calc(0px / 2) 10px calc(0px / 2);
    height: calc(100vh - 20px);
    overflow: auto;
}

.way-content .step-lists.content-page-documents-list-item.list-group {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    max-height: calc(100vh - 290px);
    min-height: calc(100vh - 290px);
    overflow: auto;
}

.way-content .list-group-item+.list-group-item.active {
    background: #f8fafc;
    border-right: 0;
    border-color: #ddd;
    font-weight: 500;
}

.way-content .create-step {
    position: absolute;
    bottom: 20px;
    width: 220px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.way-content .TextEditor {
    height: calc(100vh - 210px);
    overflow: auto;
}

/*.row{
    padding: 0 !important;    
}*/
.way-content .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: 0;
    border-left: 0;
    border-bottom: 0
}

.way-content .list-group-item:last-child {
    border: 1px solid rgba(0, 0, 0, .125);
    border-right: 0;
    border-left: 0
}

.way-content .active.list-group-item {
    background-color: #f8fafc;
    font-weight: 500;
}

.way-content .postion-absolute {
    position: absolute;
    bottom: -80px;
    border: 1px solid #eee;
    border-radius: 100%;
    width: 215px;
    height: 215px;
    text-align: center;
    padding-top: 6px;
    background: #fff;
}

.way-content .border-bottom-1 {
    border-bottom: 1px solid #ddd;
    margin-top: 35px;
}

.way-content .HeightHundred {
    height: calc(100vh - 160px);
}

.way-content .action-way {
    flex-direction: column;
}

.way-content .action-way {
    height: calc(100vh - 116px);
}

.way-content .widthWise {
    width: 55%;
    margin: 0 auto;
    position: relative;
}

.way-content tr td .dropdown-menu {
    left: auto !important;
    right: 0 !important;
}

.way-content .widthWise .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1
}

.WayBookCate .subject-data {
    margin-bottom: 5px;
}

.content-page-documents-list-item .dropdown-menu {
    right: 0 !important;
    left: auto !important;
}

.ediditor-proposl-view .row {
    display: flex;
}

.mark-option i.fas.fa-circle {
    color: #f0f4f8;
    cursor: pointer;
}

.mark-option i.fas.fa-check-circle {
    color: #f26522;
    cursor: pointer;
}

.step-content span {
    color: #f26522;
}

.way-content .progress-bar {
    background-color: #f26522;
}

.dummy-box {
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.waybook-page .row{
    padding: 0;
}

.mainCate.accordion-item a.dropdown-item {
    padding: 0.35rem 1.5rem;
}


.WayBookCate .category-action {
    display: none;
    position: absolute;
    right: 0;
    top: 10px;
    align-items: center;
}

.WayBookCate .category-action i.fas.fa-info-circle {
    margin: 0;
    line-height: normal;
    color: #f93;
}

.WayBookCate .mainCate.accordion-item .accordion-header:hover .category-action {
    display: flex;
}

td.draggable.drag-handle.document-drag-handle.has-tooltip {
    width: 6%;
}

tr.content-page-documents-list-item td {
    width: 40%;
}

.script-render {
    height: 350px;
    overflow: auto;
    border-left: 1px solid #cece;
    padding-left: 10px;
    padding-right: 10px;
}

.subject-document-lists {
    min-height: calc(100vh - 276px);
    overflow: auto;
    max-height: calc(100vh - 276px);
}

.way-content .widthWise .welcome-token .css-2b097c-container{
    position: relative;
    z-index: 9;
}
.way-sidebar {
    max-height: calc(100vh - 168px);
    min-height: calc(100vh - 168px);
    overflow: auto;
}
.way-content .action-way.current_6 {
    height: calc(100vh - 120px);
}
.subjexts.side-change-dropdown .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
}
.required {
    color: red;
    position: relative;
    font-size: 20px; /* Adjust the size as needed */
    margin-left: 3px; /* Adjust the spacing as needed */
    top: -1px; /* Adjust the vertical position */
}