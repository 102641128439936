.keypad-button {
  border: none;
  border-radius: 100%;
  background-color: #f4f4f6;
  color: #121c2d;
  width: 2em;
  height: 2em;
  font-size: 24px;
  line-height: 1;
  padding: 0;
}

.keypad-button.green {
  background-color: #36d576;
  color: #fff;
}

.keypad-button.red {
  background-color: #f22f46;
  color: #fff;
}
.keypad-button.red i.fas.fa-phone {
    transform: rotate(225deg);
}
button.keypad-button p {
    margin: 0;
    line-height: normal;
}
button.rejact, button.accept {
    width: auto;
    display: inline-block;
}
button.rejact i.fas.fa-phone {
    transform: rotate(225deg);
    width: auto;
    display: inline-block;
}
.accept-rejact button.accept, .accept-rejact button.rejact {
    color: #fff;
    background: #36d576;
    border: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
}
.accept-rejact button.rejact{
  background: #f22f46;
}

.fake-state .fake {
    display: inline-block;
}
.fake-state .fake label {
    float: left;
    margin: 0;
}
.fake-state .fake input {
    float: left;
    margin: 3px 0 0 5px;
}
.fake-state .fake:first-child {
    width: 24%;
}
.fake-state .fake {
    display: inline-block;
    width: 18%;
}
.fake-state .fake:nth-child(2) {
    width: 16%;
}
.fake-state .fake:nth-child(3) {
    width: 22%;
}
.accept-rejact {
    width: 30%;
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
}