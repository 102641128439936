/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@media print {
    .do-not-print {
        display: none;
    }
}
.fa-file-text-o:before {
    content: "\f15c"; /* Unicode for file-alt in Font Awesome 5 */
}
#page-topbar {
    box-shadow: none;
    /* background: #f4f4f4; */
}
.vertical-collpsed .vertical-menu{
    z-index: 9;
}
.header-item {
    height: auto;
}

.navbar-brand-box {
    background: #c58524;
/*    width: 253px;*/
}
.vertical-collpsed .navbar-brand-box {
    width: 18px !important;
}
.has-arrow waves-effect{

}
@media print {
    @page {
        size: landscape
    }
}

p {
    margin: 0;
}

/* ///////////////////////////////////////////////////*/
body {
    /* background-color: #f4f4f4; */
    background-color: #fff;
    /* font-family: 'Montserrat', sans-serif; 
    font-family: 'Raleway', sans-serif;*/
    font-family: 'Inter', sans-serif;
}

.bgCustomCls {
    background-color: #f4f4f4;
}

.CompanyDetail.ClientDetails h6 {
    margin: 0;
}

.row {
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

/* Unlayer Editor*/


/* a.blockbuilder-branding {
    display: none!important;
} */


/* .gCuogY .blockbuilder-branding {
    display: none!important;
} */

.vertical-align-middle {
    vertical-align: middle
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    margin: 10px 10px 10px 0;
}

.cursor-pointer {
    cursor: pointer;
}

.navbar-brand-box a.logo {
    color: #ffffff;
}

.template-email a.waves-effect.btn.btn-light {
    width: 100%;
}

.width-100-percent {
    width: 100%;
}

ul.Thead {
    padding: 0;
}

.tab-pane.template-email img {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.align-self-center {
    align-self: center;
}

.main-section-3 {
    display: flex;
    font-size: 0.7rem;
    border: 1px dotted grey;
    margin: 3% 0px;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    justify-content: normal;
    flex-direction: row;
}

.main-sub-section-3 {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.sub-header:nth-child(2n+1) {
    background-color: var(--btn-primary, #3a3b3d);
    color: rgb(255, 255, 255);
}

.sub-header {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-right: 1px dotted grey;
    width: 60px;
    font-size: 0.65rem;
}

.middle-section {
    display: flex;
    flex: 4 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px;
    font-size: 0.6rem;
}

.last-section {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-left: 1px solid grey;
    font-size: 0.65rem;
}

.survey.card.card-body table.table tr th:first-child,
.survey.card.card-body table.table tr td:first-child {
    /* min-width: 190px; */
    position: sticky;
    left: 0px;
    background: #fff;
    border-right: 1px solid #ddd;
    max-width: 250px;
    max-height: 35px;
    height: 35px;
    z-index: 3;
}

.survey.card.card-body .table tr th:nth-child(2),
.survey.card.card-body .table tr td:nth-child(2) {
    width: 50px;
    /*border-left: 1px solid #ddd;*/
    border-right: 0;
    position: relative;
}

.survey.card.card-body .table tr th {
    padding-left: 10px;
    /* width: 40px; */
}

.survey.card.card-body .table> :not(caption)>*>* {
    padding: 0;
    min-width: 30px;
    text-align: center;
    vertical-align: middle;
}

.survey.card.card-body table.table {
    border: 1px solid #e9ecef;
}

.survey.card.card-body table.table tr {
    border-bottom: 1px solid #e9ecef;
}


.survey.card.card-body .table .meeting {
    background-color: var(--btn-primary, #3a3b3d);
    color: #fff !important;
    padding: 5px;
}

.card-body table tbody tr td .imp-time {
    background-color: #14143f;
    color: #fff;
    width: 100%;
    font-size: 10px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
}

.top_navbar .top-navigation .container-fluid {
    padding-left: 0;
}

.navbar-header {
/*    background: #d5d2d2;*/
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: auto;
}

.grid-question {
    display: grid;
    grid-template-columns: 62% 37%;
    grid-column-gap: 10px;
    text-align: left;
    justify-content: center;
    align-items: end;
    width: 100%;
}

.go-result {
    width: auto;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
}

.go-result .result-btn {
    width: 120px;
    float: right;
}

.breakthrough {
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    align-items: center;
}

ul.summary-report {
    text-transform: capitalize;
    padding: 0;
    width: 95%;
    overflow: auto;
    white-space: nowrap;
    margin: 0 auto;
    height: auto;
    text-align: center;
}

ul.summary-report li {
    background-color: #C58524;
    list-style: none;
    width: 70px;
    white-space: normal;
    margin: 0 15px 0 0;
    text-align: center;
    min-width: 70px;
    max-width: 70px;
    position: relative;
    min-height: 70px;
    height: 70px;
    border-radius: 0 0 25px 25px;
}

.BottomShade {
    bottom: -16px;
    float: left;
    position: absolute;
    width: 100%;
}

.BottomShade img {
    width: 70px;
}

ul.summary-report li p {
    display: inline-block;
    padding: 5px;
    /* width: 12%; */
    text-align: center;
    margin-bottom: 0;
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 48px;
}

ul.summary-report li p:last-child {
    cursor: pointer;
}

.Toastify .Toastify__toast-container {
    z-index: 99999;
    top: 5em;
}

.fixed-faveicon {
    position: fixed;
    right: 40px;
    bottom: 50%;
    top: 45%;
    margin: auto;
    background: transparent;
}

.slot-list .list-group-item:hover {
    cursor: pointer;
    background: #c6c6c6;
}

.slot-list .list-group-item {
    cursor: pointer;
}

.custom-calander td.fc-daygrid-day.fc-day .fc-daygrid-day-top a {
    padding: 0;
}

.custom-calander td.fc-daygrid-day.fc-day .fc-daygrid-day-top {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 50px;
}

.custom-calander td.fc-daygrid-day.fc-day.selectedDate .fc-daygrid-day-top.childselectedDate {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.custom-calander td.fc-daygrid-day.fc-day.fc-day-future .fc-daygrid-day-top a {
    color: #fff;
}

.custom-calander td.fc-daygrid-day.fc-day.selectedDate .fc-daygrid-day-top.childselectedDate a {
    color: #7a6fbe;
}

.custom-calander td.fc-daygrid-day.fc-day.fc-day-today.selectedDate .fc-daygrid-day-top {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.custom-calander .fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.custom-calander .fc-day-past {
    pointer-events: none;
}

.custom-calander .fc .fc-highlight {
    background: transparent;
}

.custom-calander td.fc-daygrid-day.fc-day.fc-day-today .fc-daygrid-bg-harness .fc-highlight {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
}

.custom-calander td.fc-daygrid-day.fc-day .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top a:hover {
    color: #fff;
}

.survey .html {
    line-height: 25px;
}

.custom-calander td.fc-daygrid-day.fc-day .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top a:hover {
    color: #7a6fbe;
}

.custom-calander td.fc-daygrid-day.fc-day {
    text-align: center;
    position: relative;
    height: 63px;
}

.custom-calander.cal-custom .fc .fc-cell-shaded,
.custom-calander.cal-custom .fc .fc-day-disabled {
    background: none;
    background: none;
}

.custom-calander.cal-custom .fc td,
.custom-calander.cal-custom .fc th {
    border: 0;
}

.custom-calander th.fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion {
    color: #fff;
}

.custom-calander .fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    background: rgb(66, 91, 118);
}

.custom-calander td.fc-daygrid-day.fc-day.fc-day-today {
    background: transparent;
}

.slot-list.list-group.list-group-horizontal {
    flex-direction: column;
    text-align: center;
    height: 400px;
    overflow: auto;
    margin-top: 32px;
}

.min-height-200 {
    min-height: 200px;
}

.nav-tabs>li>button,
.nav-tabs>li>button>a,
.nav-pills>li>button {
    color: #5b626b;
    font-weight: 500;
}

ul.nav.nav-tabs:first-child {
    padding-left: 0;
}

.nav-tabs button.active.nav-link {
    border-bottom: 0 solid #33475b;
    background: var(--bd-color, #f16321);
    color: #fff !important;
}

.client-popover.show {
    max-width: 400px;
}

.d-grid-template {
    display: grid;
    grid-template-columns: 200px 150px;
    grid-column-gap: 1px;
}

.d-grid-template .info i {
    padding-right: 10px;
}

.d-grid-template .info {
    padding: 5px;
}

ul.client-search-bar li:hover {
    background: #c6c6c6;
}

ul.client-search-bar li {
    margin-top: 2px;
    list-style-type: none;
    background: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    text-align: left;
}

.parent {
    border-bottom: 1px solid #ddd;
}

.client-search-bar {
    position: absolute;
    background: #cecece;
    overflow: hidden;
    right: 10px;
    padding: 0;
    min-width: 350px;
    z-index: 1;
}

.app-search.search .form-control {
    height: 38px;
    padding-left: 10px;
    background-color: #f8f9fa;
    border-radius: 0;
}

.app-search.search button {
    position: relative;
    font-size: 13px;
    color: #5b626b;
    height: 38px;
    background-color: #d8d9d9;
    width: 38px;
    border-radius: 0;
    border: none;
}

.app-search.search {
    width: 100%;
}

.card {
    box-shadow: none;
}

.canvas-wrapper canvas {
    width: 100%;
}

.outer_type_it {
    cursor: default;
    color: #145394;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    margin-top: 14px;
}

.type_it {
    line-height: 38px;
    border-bottom: 2px solid #ccc;
}

.text-left {
    text-align: left !important;
}

.info span.badge {
    color: #fff;
}

.remove-arrow button.accordion-button::after {
    display: none;
}

.invoice.grid-style {
    grid-template-columns: 80% 15%;
}

.invoice-meta p.for {
    font-size: 85%;
    color: #888888;
    border-bottom: 1px solid #c6c6c6;
    display: block;
    padding-bottom: 8px;
    margin-bottom: 10px;
}

.invoice-data p {
    margin-bottom: 1px;
}

.none {
    display: none;
}

.space-between {
    justify-content: space-between;
}

.invoice-title p {
    margin-bottom: 0;
    font-size: 12px;
    color: #aaaaaa;
    text-transform: capitalize;
}

.time-constraint {
    background: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px 15px 15px;
}


/* .activity-bubble {
    width: 400px;
} */

.audio-react-recorder {
    height: 0px !important;
}

.activity-content {
    background: #e5f5f8;
    box-shadow: inherit;
    /* background: #edf2f6; */
    border: 1px solid #d0dae4;
    /* border-radius: 15px 15px 0 15px; */
    width: 100%;
    min-width: 350px;
    color: #3d4f5f;
    padding: 14px 20px;
    border-color: #7fd1de;
    border-radius: 15px 15px 15px 0;
}

.activity-content p {
    margin-bottom: 0;
    word-wrap: break-word;
}

.activity_time b {
    margin: 0 5px 0 0;
}

.activity_time {
    font-size: 11px;
    color: #7d98b5;
    padding: 3px 0 0 4px;
    text-transform: capitalize;
}

.CompanyDetail .activity_time {
    font-size: 12px;
    color: #555;
    padding: 0;
    text-transform: none;
    display: flex;
    justify-content: space-between;
}

.CompanyDetail .activity_time span {
    font-weight: 500;
    font-size: 14px;
    color: #444;
}

.CompanyDetail .activity-content {
    min-width: 100%;
    color: #3d4f5f;
    padding: 5px;
    width: 100%;
    background-color: #f9f9f9;
    /* box-shadow:0px 0 5px 2px #ddd; */
    border-radius: 3px;
    border: 1px solid #ddd;
    text-align: left;
}

.page-content.dialer .modal-head.top-heading h2 {
    font-size: 20px;
    margin: 0;
    line-height: normal;
}

.CompanyDetail .activity-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.CompanyDetail .activity-body {
    width: 100%;
    margin-left: 45px;
    position: relative;
    margin-right: 5px;
}

.NoShadow.card .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.client-info {
    padding: 10px;
    color: #000;
}

.CompanyDetail .details .accordion-body .card {
    border: 1px solid #eee;
    box-shadow: none;
    margin: 0;
    margin-bottom: 10px;
}

.client-info a h4 {
    margin: 0;
    color: #7a6fbe;
    font-size: 14px;
}

.url button.btn.btn-link.btn-md {
    padding: 1px 5px;
}

.CompanyDetail .card-body {
    padding: 0 .7rem;
}

.user-profile-pic-area {
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
}

.user-profile-pic-area .card-header {
    background: var(--bd-color, #f16321);
    border-radius: 0;
}

.user-profile-pic-area .card-header .top-heading.WithDrop a,
.user-profile-pic-area .card-header .top-heading.WithDrop .dropdown button.btn {
    color: #fff !important;
    padding: 0;
}

.user-profile-pic-area .card-header .top-heading.WithDrop .d-inline.show.dropdown .dropdown-menu.show a {
    color: #000 !important;
    padding: 0 5px;
    font-size: 14px;
}

.user-profile-pic-area .card {
    border-radius: 0;
}

.user-profile-pic-area .card-header .top-heading.WithDrop .dropdown button.btn i.ion.ion-md-flower {
    font-size: 19px;
}

.page-content.dialer .NoShadow.card .details .accordion-item {
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    margin: 0;
}

.page-content.dialer .NoShadow.card .details .accordion-body {
    padding: 0 1.25rem;
}

/* .page-content.dialer .NoShadow.custom-scroll.Activity.card .nav-tabs {
    margin-top: 2rem;
} */

.client-info a {
    color: #000;
    font-size: 14px;
}

.company-info .info h3 {
    font-weight: 500;
    color: #000;
    font-size: 20px;
    margin: 0;
}

.url a,
.url button,
.company-info .info a,
.company-info .info button {
    color: #222;
}

.searchBtn {
    position: absolute;
    top: 4px;
    right: 6px;
    color: #697dbf;
}

.relative {
    position: relative;
}

.page3 .nav-tabs {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.page3 .nav-tabs button.nav-link {
    color: #000;
}

.page3 .nav-tabs button.nav-link.active {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-color: #ccc;
    color: #000;
}

.page-content.dialer .page3.invoice-details {
    width: 50%;
}

.accordion-head.task {
    grid-template-columns: 60% auto;
    justify-content: space-between;
}

.accordion-head {
    display: grid;
    grid-template-columns: 90% auto;
}

.CompanyDetail.ClientDetails .remove-arrow {
    grid-template-columns: 85% auto;
}

.accordion-head.CompanyBtn {
    grid-template-columns: auto;
}


.NoShadow.card {
    box-shadow: none;
    /* border: 2px solid #e2e2e2; */
    border-radius: 0;
    height: calc(100vh - 112px);
    background: #fff;
    overflow: auto;
}

/* .NoShadow.card.ProfileClientActivity{
    height: calc(100vh - 144px);
} */
.NoShadow.card.ProfileClientActivity {
    height: auto;
    display: inline-block;
    overflow: unset;
    width: 100%;
}

.CompanyDetail.ClientDetails .NoShadow.card {
    height: calc(100vh - 120px);
    margin: 0;
    box-shadow: 0px 0 5px 2px #ccc;
}

.compant-action-btn button {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 0;
}

.NoShadow.card .accordion-item {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.NoShadow.traning.card .accordion-item,
.NoShadow.HeightAuto.card .accordion-item {
    margin-bottom: -1px
}

.NoShadow.traning.card .accordion-item table.table td a.dropdown-item {
    padding: 1rem 2rem;
}

.NoShadow.traning.card .accordion-item table.table {
    margin: 0;
}

.NoShadow.traning.HeightAuto.card,
.NoShadow.traning.HeightAuto.Playlist.card {
    height: 460px;
    overflow: auto;

}

.NoShadow.traning.HeightAuto.Playlist.card {
    padding: 0 15px;
}

.NoShadow.traning.HeightAuto.VideoHeight.card {
    height: 480px;
}

.NoShadow.traning.card .accordion-item table.table td {
    padding: 0;
}

.Playlist .accordion-item table.table td:hover {
    background: #f1f1f1
}

.NoPadding.card-body {
    padding-bottom: 0;
}

.NoShadow.traning.HeightAuto.card .card-body {
    padding-left: 0;
}

.NoShadow.traning.card .accordion-body {
    padding: 0;
}

.NoShadow.HeightAuto.card {
    height: auto;
    border-right: 0;
}

.NoShadow.HeightAuto.card .accordion-item .accordion-body {
    border-top: 1px solid #ddd;
}

.page-content.dialer .NoShadow.card .accordion-item {
    border-left: 0;
    border-right: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(45 62 80 / 12%) 0px 1px 5px 0px;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 0
}

.page-content.dialer .card.card-body {
    box-shadow: none;
    padding: 0;
}

.NoShadow.card .accordion-item button.accordion-button.collapsed {
    font-weight: 500;
    font-size: 14px;
}

.NoShadow.card .accordion-item h2.accordion-header button.accordion-button {
    background: transparent;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    padding-right: 12px;
    padding-left: 5px;
}

/* .company-info .logo {
    flex-basis: 18%;
    text-align: right;
} */
.company-info .logo {
    text-align: center;
}

.top-heading.WithDrop .show.dropdown .dropdown-menu {
    right: -28px !important;
}

/* .company-info .info {
    flex-basis: 75%;
} */
.company-info .info {
    flex-basis: 100%;
    text-align: center;
}

.compant-action-btn button i {
    font-size: 13px;
}

.nav-tabs a.active.nav-link {
    border-bottom: 0;
    background: var(--bd-color, #f16321);
    color: #fff;
}

.nav-tabs {
    border-bottom: 1px solid #ced4da;
    padding: 16px 0 0 10px;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background: rgb(234, 240, 246);
    color: #000;
    margin: 0 2px 0 0;
    border-bottom: 0;
    padding: 5px 10px !important;
    font-weight: normal;
}

.NoShadow.card .card-header {
    background: #fff;
}

.page2 .custom-setting input#phone,
.page3 .custom-setting input#phone {
    width: 100px;
}

.page2 .custom-setting span.input-group-addon,
.page3 .custom-setting span.input-group-addon {
    padding: 9px 5px;
}

.modal-head {
    /*background-color: #C58524;*/
    background-color: #C58524;
    /*background-image: linear-gradient(-303deg, #00a4bd, #00afb2 56%, #C58524);*/
    color: #fff;
    padding: 10px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 9;
}

.page-content.dialer .page3 {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 40%;
    overflow-x: hidden;
}


/*.form-control {
    padding: 0.375rem 0.25rem;
}*/

.page-content.dialer .modal-head {
    background-color: #C58524;
    color: #fff;
    padding: 10px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.page2 {
    background-color: #fff;
    box-shadow: 3px 0 8px 0 rgb(66 91 118 / 21%);
    margin: 0;
    padding: 0;
    z-index: 8;
    top: 54px;
    right: 0;
    position: fixed;
    width: 60%;
    height: 100vh;
    overflow: auto;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}
.page4 {
    background-color: #fff;
    box-shadow: 3px 0 8px 0 rgb(66 91 118 / 21%);
    margin: 0;
    padding: 0;
    z-index: 8;
    top: 54px;
    right: 0;
    position: fixed;
    width: 35%;
    height: 100vh;
    overflow: auto;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.CompanyDetail .page2 {
    width: 40%;
    top: 0;
    z-index: 9999;
    overflow-x: hidden;
}

.CompanyDetail .page2 .modal-head.top-heading button,
.page-content.dialer .page3 .modal-head.top-heading button {
    font-size: 22px;
    padding: 0;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
}

.ClientProfileDetails .LeftSideUser .accordion-button:not(.collapsed)::after {
    font-family: 'themify';
    color: #fff;
    font-size: 15px;
    content: "\e64b";
    background-image: none;
}

.accordion-button::after {
    transform: rotate(-90deg);
}

.page3 {
    background-color: #fff;
    box-shadow: 3px 0 8px 0 rgb(66 91 118 / 21%);
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    position: fixed;
    width: 40%;
    height: 100vh;
    overflow: auto;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    z-index: 9999;
    overflow-x: hidden;
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

.slide-out2 {
    animation: slide-out2 0.5s forwards;
    -webkit-animation: slide-out2 0.5s forwards;
}

.page-content .meeting-detail.card {
    margin-top: 0.9rem;
}

.page-content .meeting-detail .meeting {
    text-align: center;
}

.ask-btns button {
    margin: 0 10px 30px;
}

.ask-btns button.btn.btn-outline-secondary i {
    font-size: 14px;
}

.ask-btns a i {
    font-size: 20px;
    margin-top: 0;
    display: inline-block;
}

.page-content .meeting-detail .meeting h4 {
    text-align: left;
}

ul.user-details {
    margin: 0;
    list-style: none;
    text-align: left;
    padding: 0;
}

ul.user-details li {
    font-size: 20px;
    padding: 10px 0;
}

.accordion.products .accordion-item button {
    background: var(--bd-color, #f16321);
    box-shadow: none;
    border-radius: 0;
    color: #fff;
    padding: 0;
    padding-right: 1.25rem
}

.accordion.products .accordion-body .accordion-item button.accordion-button {
    padding: 1rem 1.25rem
}

.accordion.products .accordion-item {
    margin-bottom: 10px;
    box-shadow: none;
    border-radius: 0;
}

.accordion.products .accordion-body .accordion-item button.accordion-button {
    background: #f9f9f9;
    color: #555;
}

.accordion.products .accordion-item .accordion-body .accordion .accordion-body a.dropdown-item {
    padding: 10px 1.8rem;
    border-bottom: 1px solid #eee;
}

.accordion.products .accordion-item .accordion-body .accordion .accordion-body a.dropdown-item:last-child {
    border-bottom: 0
}

.accordion .dropdown-item {
    font-size: 14px;
    padding-left: 0;
}

.accordion .dropdown-item:hover,
.accordion .dropdown-item:focus {
    color: #2c3034;
    background-color: transparent;
    text-decoration: underline !important;
}

.accordion.products .accordion-item button img {
    width: 100px;
    margin: 0 10px 0 0px;
    height: 60px;
    object-fit: cover;
}

#navigation ul.navbar-nav:first-child {
    height: 49px;
    align-items: center;
    display: flex;
/*    margin: auto;*/
    justify-content: center;
}

.topnav .navbar-nav .nav-link span {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.topnav .navbar-nav .nav-link {
    /* line-height: 15px; */
    text-transform: uppercase;
}

.topnav .navbar-nav .nav-link {
    white-space: normal;
}

#navigation ul.navbar-nav:first-child li.nav-item a i.ti-dashboard {
    margin: 2px 5px 0 0;
}

.top_navbar {
    height: 100%;
}

.navbar-header .float-end {
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
}

.dropdown.ms-2.SearchDropDown .dropdown-menu.show {
    right: 0;
}

.topnav {
    margin-bottom: 0;
    margin-top: 0;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li:after {
    content: "";
    border-top: 2px dashed #9b9b9b;
    left: 50%;
    display: block;
    position: absolute;
    width: 50%;
    top: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer:after {
    border: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li.tree-condition-li-no:after {
    left: auto;
    right: 50%;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer.yes-outer ul li:after {
    border: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer {
    border: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer ul li {
    border: 0;
}

ul.tree-point li ul.tree-condition li.tree-condition-li-no ul li.plus_outer li:after {
    border: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer ul li.tree-condition-li-yes:after {
    content: "";
    border-top: 2px dashed #9b9b9b;
    left: 50%;
    display: block;
    position: absolute;
    width: 50%;
    top: 0;
}

ul.tree-point li ul.tree-condition li ul.tree-condition-ul li ul li.plus_outer ul li.tree-condition-li-no:after {
    content: "";
    border-top: 2px dashed #9b9b9b;
    left: 0;
    display: block;
    position: absolute;
    width: 50%;
    top: 0;
}

.event-tree {
    text-align: center;
    min-width: 5200px;
    position: relative;
}

.event-tree h5 {
    border: 2px dashed #9b9b9b;
    display: inline-block;
    min-height: 50px;
    width: 250px;
    line-height: 50px;
}

.event-tree:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    border-left: 2px dashed #9b9b9b;
    width: 0;
    height: 22px;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-110%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-110%);
    }
}

@keyframes slide-out2 {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(110%);
    }
}

@-webkit-keyframes slide-out2 {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(110%);
    }
}

.page2Visible {
    visibility: visible;
    opacity: 1;
}


/*  */

.info span {
    font-size: 12px;
    color: #000;
    font-weight: 400;
}

.page-content.dialer .page3.deal-assign {
    width: 20%;
}

.pull-right {
    float: right;
}

.compant-action-btn button {
    margin: 15px 1px 0;
}

.activity-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}

.info p {
    margin: 0;
    line-height: normal;
    font-size: 14px;
}

.page-content.dialer .NoShadow.card .details .accordion-body .info span {
    font-size: 12px;
    color: #555;
}

.page-content.dialer .NoShadow.card .details .accordion-body .info p {
    font-size: 13px;
    color: #222;
}

/* .company-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
} 
.company-info {
    justify-content: left;
    display: flex;
    column-gap: 14px;
    align-items: center;
}*/

.page-content.dialer .NoShadow.custom-scroll.Activity.card h2.accordion-header button {
    font-weight: 400;
    font-size: 14px;
    color: #999;
    padding: 10px;
}

.btn-link {
    background: transparent !important;
    border: 0 !important;
}

.top-heading {
    display: flex;
    justify-content: space-between;
}

.input-group.asrt-page-length {
    align-items: center;
}

.visibilty-hidden {
    visibility: hidden;
}

.input-group-addon {
    padding: 9px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-group-addon,
.input-group-btn {
    width: auto;
    white-space: nowrap;
    vertical-align: middle;
}

.input-group-addon {
    border-left-width: 0;
    border-right-width: 0;
}

.input-group-addon:first-child {
    border-left-width: 1px;
}

.input-group-addon:last-child {
    border-right-width: 1px;
}

.height-350 {
    height: 350px;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
}

.height-500 {
    height: 500px;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
}

.grid-style {
    display: grid;
    grid-template-columns: 85% 10%;
    grid-column-gap: 4%;
    align-items: center;
}

.width-200 {
    width: 200px !important;
}

.width-350 {
    width: 350px;
}



.p0 {
    padding: 0;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.p-10 {
    padding: 10px;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pr-5 {
    padding-right: 5px;
}

button,
a {
    outline: none;
}

button {
    box-shadow: none;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-50 {
    margin-right: 50px;
}


/*.vertical-menu {
    width: inherit;
}*/


/*.vertical-menu{
    min-width: 250px;
    width: auto;
}*/

/* .footer {
    height: 22px;
    line-height: 22px;
    padding: 0;
    z-index: 1;
} */
.footer {
    height: 22px;
    line-height: 22px;
    padding: 0;
    z-index: 1;
    position: fixed;
    bottom: 0;
}

.as-react-table .float-right.text-right {
    text-align: right;
}

.as-react-table .table-body {
    overflow: auto;
}

.text-align-right {
    text-align: right;
}

.danger {
    color: #ec536c !important;
}

.success {
    color: #58db83 !important;
}

.user-profie {
    display: grid;
    grid-template-columns: 40% 60%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #dee2e6;
    border-radius: 0.25rem;
    margin-bottom: 24px;
    box-shadow: 0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%);
}

.user-profie img.profile-img {
    width: 100%;
    height: auto;
}

.table-responsive::-webkit-scrollbar {
    width: 5px;
}


/* Track */

.table-responsive::-webkit-scrollbar-track {
    background: #d9d9d9;
}


/* Handle */

.table-responsive::-webkit-scrollbar-thumb {
    background: #454444;
    border-radius: 5px;
}


/* Handle on hover */

.table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.upload-file .dropzone {
    min-height: unset;
    height: 40px;
    padding: 7px 18px;
}

a.btn-light,
button.btn-light {
    background-color: transparent;
    border-color: transparent;
}

.btn-custom {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    background: transparent;
    border: transparent;
}

.btn-custom:hover,
.btn-custom:active,
.btn-custom:focus,
.btn-secondary:active:focus,
.btn-secondary:active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.btn-round {
    border-radius: 50px;
    padding: 5px 20px;
}

span.warning .mdi-alert-circle {
    font-size: 50px;
    color: #ff9800;
}

.mini-stat.bg-primary.card {
    background: var(--bd-color, #d64e0f) !important;
}

.bg-primary {
    background: var(--bd-color, #d64e0f) !important;
}

.btn-primary,
.btn-secondary,
.btn-primary:disabled,
.btn-primary.disabled,
.bg-primary-color {
    color: var(--btn-primary-text, #fff);
    background-color: #c58524;
    border-color: #c58524;
}
#vertical-menu-btn i {
    color: #000 !important;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-primary:disabled:hover,
.btn-primary.disabled:hover {
    background-color: var(--btn-primary-hover-text, #fff);
    background-color: var(--btn-primary-hover, #3a3b3d);
    border-color: var(--btn-primary-hover, #3a3b3d);
}

.widget-user .widget-user-desc:after {
    border-top-color: var(--bd-color, #d64e0f) !important;
    border-right-color: var(--bd-color, #d64e0f) !important;
}

.btn-danger {
    color: var(--btn-danger-text, #fff);
    background-color: var(--btn-danger, #ec536c);
    border-color: var(--btn-danger, #ec536c);
}

.btn-danger:hover {
    color: var(--btn-danger-hover-text, #fff);
    background-color: var(--btn-danger-hover, #c9475c);
    border-color: var(--btn-danger-hover, #c9475c);
}

.btn-info {
    color: var(--btn-info-text, #fff);
    background-color: var(--btn-info, #145DA0);
    border-color: var(--btn-info, #145DA0);
}

.btn-info:hover {
    color: var(--btn-info-hover-text, #fff);
    background-color: var(--btn-info-hover, #239fc1);
    border-color: var(--btn-info-hover, #239fc1);
}


/*.page-content {
     padding: calc(40px + 24px) calc(0px / 2) 55px calc(0px / 2); 
    padding: calc(70px + 24px) calc(0px / 2) 60px calc(0px / 2);
}*/

.page-content {
    background: #f5f5f5;
    padding: calc(30px + 33px) calc(0px / 2) 10px calc(0px / 2);
    height: calc(100vh - 20px);
    overflow: auto;
}

/* .page-content .card {
    margin: 0
} */
.page-title-box {
    padding-bottom: 10px;
}

/*.page-content.dialer {
    padding: calc(12px + 38px) calc(0px / 2) 15px calc(0px / 2);
}*/

.page-content.dialer .NoShadow.card {
    border-right: 0;
    border-radius: 0;
    height: calc(100vh - 112px);
    background: #fff;
    overflow: auto;
    box-shadow: none;
    border: 2px solid #e2e2e2;
}

.page-content.dialer .NoShadow.custom-scroll.Activity.card .NoShadow.card {
    background: transparent;
    box-shadow: none;
}

.modal-head.top-heading h2 {
    font-size: 20px;
    line-height: normal;
    margin: 0
}

.modal-head.top-heading button {
    padding: 0;
    font-size: 20px;
}

.view-date button.btn.btn-link,
.view-date a.btn.btn-link {
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin: 0 15px 0 0px;
}

.view-date button.btn.btn-link:focus,
.view-date a.btn.btn-link:focus {
    outline: none;
    box-shadow: none;
}

.page-content.dialer .NoShadow.custom-scroll.Activity.card .accordion-item {
    margin-bottom: 0px;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
}

.tab-pane.secondary-tabs.profilePopup .card-body .card {
    max-width: unset;
}

.tab-pane.secondary-tabs.profilePopup .card-body ul .ContactDescription {
    width: 100%;
}

.tab-pane.secondary-tabs.profilePopup .card-body ul li {
    width: 98%;
    margin: 0 auto;
    display: block;
}

.NoShadow.custom-scroll.Activity.card .tab-pane.secondary-tabs.profilePopup .card-body {
    min-height: 516px;
}

.NoShadow.custom-scroll.Activity.card .tab-pane.secondary-tabs.profilePopup .card-body {
    min-height: auto;
}

.page-content.dialer .page3 .card {
    box-shadow: none;
    margin: 0;
}

.page-content.dialer .NoShadow.custom-scroll.Activity.card {
    background: #fff;
    margin: 0;
    box-shadow: none;
    overflow: hidden;
    border: 2px solid #e2e2e2;
}

/* .page-content.dialer .NoShadow.custom-scroll.Activity.card ul.nav.nav-tabs {
    justify-content: space-between;
} */

.Activity.card .tab-content .PrimaryTabs {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 190px);
}

.secondary-tabs .tab-content {
    height: calc(100vh - 210px);
    overflow: auto;
    padding-right: 5px;
}

.pipeline-status .css-2b097c-container {
    max-width: 900px;
}

.tab-pane.secondary-tabs .css-2b097c-container {
    z-index: 3;
}

.piple-status button {
    width: 220px;
    height: 40px;
    background: transparent;
    text-align: left;
    border-color: #ccc;
    color: #666 !important;
}

.piple-status button:hover,
.piple-status button:active,
.piple-status .show>.btn-primary.dropdown-toggle,
.piple-status .show>.btn-primary.dropdown-toggle:focus,
.piple-status .btn-check:focus+.piple-status .btn-primary,
.piple-status .btn-primary:focus {
    background: transparent;
    border-color: #ccc;
    color: #999;
    box-shadow: none;
}

.piple-status .dropdown-menu.show {
    top: 29px !important;
}

.page-content.chat {
    padding: calc(40px + 15px) calc(0px / 2) 15px calc(0px / 2);
    height: calc(100vh - 20px);
}

.page-content.chat main.chat-box .card.border {
    margin: 0;
    height: calc(100vh - 111px);
}

.logo-bg {
    background: #fff;
}

.noti-icon i {
    font-size: 18px;
    color: #000;
}

.noti-icon .badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 3px;
}

.nav-bg {
    background: var(--bd-color, #f16321);
    color: var(--text-color, #fff);
    /* background: #f16321; */
    position: relative;
    margin-top: 10px;
    height: 49px;
}

.title-border-bottom,
h2.title-border-bottom {
    /* border-bottom: 1px solid var(--bd-color, #f16321); */
    color: var(--bd-color, #f16321);
    min-width: 100%;
}

.assessment-vertical-menu {
    z-index: 2;
}


.sub-nav-bg {
    background: var(--bd-color, #454444);
    color: var(--text-color, #fff);
    /* background: #f16321; */
}


/* .top-navigation .container-fluid {
    padding-left: 0;
    padding-right: 0;
} */

.dropdown button.btn {
    margin-top: 13px;
}

.widget-user.card .h2.text-white-50 {
    color: var(--text-color, #fff) !important;
}

/* .topnav .navbar-nav .nav-link {
    color: var(--nav-text-color, #fff) !important;
} */
.topnav .navbar-nav .nav-link {
    color: #fff !important;
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
    color: var(--nav-hover-text-color, #fff) !important;
}

.text-transform-capitalize {
    display: flex;
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.display-inline-block {
    display: inline-block;
}

.display-inline {
    display: inline;
}


/* nav-hover-text-color */


/* /////////////////////////////////////////////////////////////////////// */

body {
    margin-top: 20px !important;
}

.chat-online {
    color: #34ce57
}

.chat-offline {
    color: #e4606d
}

.chat-user-list {
    height: calc(100vh - 180px);
    overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
    width: 10px;
}

.custom-scroll {
    scrollbar-width: thin;
    scrollbar-color: red;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #fff;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #c7c7c794;
    border-radius: 6px;
    border: 3px solid #fff;
}

.chat-messages {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 250px);
    overflow-y: auto;
    background: #fff;
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.card.border .border-bottom {
    min-height: 63px;
}


.border:last-child {
    border-bottom: 1px solid #dee2e6 !important;
}

.border {
    border-bottom: 0 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.float-right {
    float: right !important;
}

.border-0 {
    border: 0 !important;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.css-flex-table {
    display: flex;
    width: 100%;
}

.css-flex-table-header,
.css-flex-table-body {
    display: flex;
    flex: 0 0 100%;
}

.css-flex-table,
.css-flex-table-body {
    flex-wrap: wrap;
}

.css-flex-table-header div {
    padding: 6px;
    text-align: center;
    font-weight: bold;
    background-color: rgb(191, 191, 191);
}

.css-flex-table-header div,
.css-flex-table-body .dl-list .dl-itemm .row div {
    flex: 0 1 100px;
    padding: 0 6px;
    border: 1px solid rgb(255, 255, 255);
    box-sizing: border-box;
}

.css-flex-table-header div:nth-of-type(5n),
.css-flex-table-body .dl-list .dl-item .row div:nth-of-type(5n) {
    flex: 0 1 calc(100% - 308px);
}


/* ////////////////////////////////////////////////////////////////////////// */

ul.tree-point li ul.tree-condition {
    max-width: 100%;
    display: inline-block;
    text-align: center;
}

ul.tree-point li ul.tree-condition li {
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 15px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    min-width: 300px;
    float: left;
}

.card.card-body.house-tree {
    overflow: auto;
}

ul.tree-point {
    list-style: none;
    width: auto;
    margin: 0 auto;
    padding: 0;
    min-width: 5200px;
    overflow: auto;
}

ul.tree-point li.plus_outer.startNow:before {
    border: 0;
}

ul.tree-point li {
    text-align: center;
    padding-top: 18px;
}

ul.tree-point li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px dashed #9b9b9b;
    width: 0;
    height: 14px;
}

ul.tree-point li ul {
    list-style: none;
    padding: 0;
    text-align: center;
    /* width: 50%; */
    margin: 0 auto;
    position: relative;
}

.sb_button {
    display: inline-block;
}

a.plus {
    border: 1px dashed #ccc;
    border-radius: 50%;
    width: 25px;
    display: inline-block;
    height: 25px;
    line-height: 20px;
    text-align: center;
    padding: 0;
}

button.notes-list {
    color: #fff;
    background: var(--bd-color, #f16321);
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: relative;
}

button.notes-list i {
    font-size: 9px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.button-notes-view {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.show-notes {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}

.show-notes .notes {
    padding: 10px 0;
    border-bottom: 1px solid #ced4da;
    display: flex;
    justify-content: space-between;
    padding-right: 2px;
}

.add_note_drapdown_button {
    padding: 10px 0px;
}

.cursor-pointer {
    cursor: pointer;
}


a.delete {
    color: #fff;
    background: var(--bd-color, #f16321);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    font-size: 10px;
}

.sb_button .sb_button_inner.btn-primary.sb_editable {
    border-radius: 5px;
    line-height: 25px;
    padding: 5px;
    min-width: 90px;
    display: inline-block;
    cursor: pointer;
}

.Priorities-items {
    width: 20.2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: -1px -1px 0 0;
    padding: 5px;
}

.css-flex-table-body {
    width: 100%;
    flex-direction: column;
}

.dl-item {
    display: flex;
    margin-bottom: 5px;
}

.css-flex-table-header {
    display: flex;
    align-items: center;
}

.css-flex-table-header .Priorities-items {
    flex: none;
    min-height: 41px;
    display: flex;
    flex-direction: inherit;
}

.css-flex-table-body {
    border: 0;
}

.css-flex-table-body .dl-item {
    margin: 0 0 -1px 0;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
}

.css-flex-table-body .dl-item:nth-child(even) {
    background: #f9f9f9;
}

.css-flex-table-body .dl-item:nth-child(odd) {
    background: #fff;
}

#action-nav .btn-group-vertical.left_vertical_tabs {
    background: #444;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
}

#action-nav .btn-group-vertical.left_vertical_tabs button.btn.green.dropdown-toggle {
    color: #fff;
    text-align: left;
    border-bottom: 1px solid #555;
}

#action-nav .btn-group-vertical.left_vertical_tabs button.btn.green.active.dropdown-toggle {
    background: var(--bd-color, #f16321);
}

.notify-section {
    background: #f9f9f9;
}

.status-radio input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 1;
    display: block !important;
    opacity: 0;
    cursor: pointer;
}

.status-radio {
    width: 100%;
    height: 96px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    margin: 0;
    text-align: center;
    line-height: 116px;
    position: relative;
}

/* .radio-custoom-flow {
    display: inline-block;
    width: 96px;
    margin: 0 15px;
    float: left;
} */

.radio-custoom-flow .form-check.form-check-inline {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.status-radio i {
    font-size: 20px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    width: 25px;
    height: 25px;
}

.radio-custoom-flow .form-check.form-check-inline input {
    margin: 0;
    width: 100%;
    height: 100%;
}

.status-radio.checked-radio i {
    color: var(--bd-color, #d64e0f) !important;
}

.sb_button.conditional {
    display: block;
}

.report {
    padding: 8px;
    border: 1px solid #ddd;
}

.report.odd {
    background: #e8e8e8;
}

.font-size-14 {
    font-size: 14px !important;
}

.progresss span.labels {
    width: 80%;
    display: inline-block;
}

.progresss {
    text-align: center;
}

.chart-data p {
    font-size: 12px;
    line-height: normal;
    vertical-align: middle;
    align-items: center;
    display: flex;
    margin: 0;
    height: 30px;
    margin-bottom: 10px;
}

.common-tri {
    display: inline-block;
    position: relative;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    height: 30px;
    width: 100%;
    border-radius: 50px;
    line-height: 30px;
    margin-bottom: 10px;
}

.common-tri.unassigned {
    background: #1e97f3;
}

.common-tri.proposal {
    background: #FFA500;
}

.common-tri.closed {
    background: #e1ad01;

}

.common-tri.booked {
    background: #476930;
}

.chart-data p:last-child {
    margin: 0;
}

.campagian td {
    vertical-align: middle;
}

.campagian td.td-first,
.campagian td.td-last {
    padding: 0.85rem 0.25rem;
    text-align: center;
    vertical-align: middle;
}

.campagian td.td-last a.btn,
.campagian td.td-last button.btn.btn-danger {
    width: 90%;
    margin: 0;
}

.justify-center {
    justify-content: center;
}

/*td.first-td {
    width: 25%;
}
td.mid-td {
    width: 50%;
}
td.last-td {
    width: 25%;
}
*/

.comapign-dialer {
    margin-top: -20px;
}

.call_durt {
    background: #703CE8;
    margin: 20px;
    padding: 5px 0;
    border-radius: 4px;
    float: left;
}

.call_durt .user_info {
    display: inline-table;
    font-size: 12px;
    padding: 0 6px;
    color: #fff;
    text-transform: none;
}

.call_durt .user_info span {
    color: #fff;
}

.nav_tab {
    background: #1f1b1c;
}

.voice_option_inner button {
    color: #fff;
}

.voice_option_inner button.btn-white {
    background-color: #fff;
    color: #000;
}

.voice_option_inner button:hover {
    background-color: var(--bd-color, #d64e0f);
    color: rgb(255, 255, 255);
}

.bg-gray {
    background: #e9e7e6;
}

.client-form {
    height: calc(100vh - 137px);
    overflow-y: auto;
    overflow-x: hidden;
}

.page-content.dialer.campaign-dialer {
    padding: 0;
}

.options-default {
    position: fixed;
    right: 10px;
    width: 17px;
    bottom: 384px;
    z-index: 99;
}

.options-default button.btn {
    -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    font-size: 21px;
    /*top: 50%;*/
    background: #F5B225;
    color: #fff;
}

.options-default .email-btn {
    top: 40px;
    position: relative;
}

.options-default .rvm-btn {
    top: 117px;
    position: relative;
}

.options-default .call-btn {
    top: 80px;
    position: relative;
}

.comapign-dialer .page2.slide-in.custom-scroll.invoice-details {
    top: 71px;
}

.dailer-pad.card-body {
    padding: 1rem;
}

a.btn.noti-icon.header-btn.waves-effect.display-inline {
    background: #703CE8;
    height: 40px;
    line-height: 45px;
    display: inline-block;
    margin: 0 0px 0 10px;
    padding: 0;
    width: 50px;
}

.vertical-menu {
/*    width: 16.7%;*/
    z-index: 7;
}

.select2-selection__menu {
    position: unset !important;
}

.page3.slide-in.custom-scroll.w-100 {
    z-index: 1050;
}

.incoming .options-default {
    top: 280px;
}

.incoming-twilio-call {
    margin: 20px;
}

.incoming-twilio-call .accept-rejact button {
    margin: 0 20px;
}

.incoming-twilio-call .accept-rejact {
    width: unset;
    margin: 0 15px;
    display: unset;
    justify-content: unset;
}

.incomint-notify i.fas.fa-phone {
    background: #333;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: #36d576;
    border-radius: 50%;
}

h4.incomint-notify {
    color: #000;
}

.group-addnon .input-group {
    display: grid;
    grid-template-columns: 34px auto;
}

.package-add .custom-checkbox {
    width: unset;
}

img.training-products {
    height: 232px;
    width: 370px;
}

/* input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button {
  display: none;
} */

/*
input[type="date"]::-webkit-datetime-edit {
    padding: 1em;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
    background: silver;
}

input[type="date"]::-webkit-datetime-edit-text {
    color: red;
    padding: 0 0.3em;
}

input[type="date"]::-webkit-datetime-edit-month-field {
    color: blue;
}

input[type="date"]::-webkit-datetime-edit-day-field {
    color: green;
}

input[type="date"]::-webkit-datetime-edit-year-field {
    color: purple;
}

input[type="date"]::-webkit-inner-spin-button {
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: orange;
}

input[type="date"] {
    background: transparent;
    color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
}*/

input[type=date]::-webkit-clear-button {
    display: none !important;
    -webkit-appearance: none !important;
    margin: 0 !important;
    background: red;
    opacity: 0
}

input[type="date"]::-webkit-clear-button,
input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="date"]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}

.api-custom .accordion-item {
    border-color: #49cc90;
    background: rgba(73, 204, 144, .1);
}

.api-custom .accordion-button:not(.collapsed) {
    color: #000;
    background-color: rgba(73, 204, 144, .1);
}

.highlight-code pre {
    background: #C3FFA5;
    border: solid 1px #19A347;
    color: #191919;
    display: block;
    font-size: 12px;
    margin: 8px;
    padding: 4px;
}

.api-custom.card-body h2.accordion-header button.accordion-button {
    padding: 0.5rem;
}

.profile-tabs.nav.nav-tabs a.nav-link {
    border-radius: 0.25rem;
}

.invoice-title h2 {
    font-size: 26px;
    text-align: center;
}

address.mb-0.float-end {
    width: 100%;
    text-align: right;
}

.invoice-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.invoice-logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.invoice-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

table.table.coach-invoice thead tr th {
    color: #fff;
    background: var(--bd-color, #d64e0f) !important;
}


.company.border-bottom button.btn.btn-link.btn-md.pull-right {
    position: absolute;
    right: 25%;
    /* right: 0; */
}

.dialer .loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999999999999999999999
}

.comapign-dialer .nav-bg {
    color: #000;
}

.comapign-dialer .pipeline ul,
.comapign-dialer {
    color: #000 !important;
}

.custom-field-page .input-group.mb-3.form-group {
    display: flex;
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
}

.marketplace_card {
    position: relative;
    display: block;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #d1d6d9;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin: 10px 0;
}

.containerw {
    height: 169px;
    position: relative;
    display: flex;
    vertical-align: middle;
    overflow: hidden;
}

.containerw .image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: inherit;
    margin: auto;
    display: table;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    line-height: 169px;
    z-index: 1;
}

.middle a.text {
    position: relative;
    z-index: 999;
}

.containerw:after {
    background: rgba(0, 0, 0, .5);
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.marketplace_card .text {
    color: white !important;
    font-size: 16px;
    transition: .5s ease;
    text-decoration: none;
}

/* .market_content {
    background: #f9f9f9;
} */

.market_content {
    padding: 10px;
    position: absolute;
    background: transparent;
    width: 70%;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    color: #fff;
    font-size: 18px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all .5s;
}

.market_content .market_price {
    font-size: 14px;
    display: table;
    width: 100%;
}

.market_content strong,
.market_content .market_price {
    font-size: 14px;
    color: #3e474f;
    font-weight: bold;
}

.market_content .market_price label {
    margin: 0;
    float: left;
    line-height: 22px;
}

.market_content .market_price .btn {
    width: auto;
    padding: 5px 8px;
    display: inline-table;
    font-size: 12px;
    float: right;
    margin: 0 0 0 5px;
}

.containerw:hover .image {
    opacity: 0.3;
}

.containerw:hover .middle {
    opacity: 1;
}

.NoShadow.traning.HeightAuto.video.card {
    background: var(--bd-color, #f16321);
}

.accordion-body .table tr.active {
    background: #f1f1f1;
}

.accordion-body .table tr.active td .grid-style a {
    color: #000;
}

.accordion-body .table tr td .grid-style a {
    padding: 0.35rem 0.5rem;
    clear: both;
    font-weight: 400;
    color: #444;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Playlist .progress-bar {

    background: #f16321;
}

.Playlist i.fas.fa-check-circle {
    color: #f16321;
}

/*:focus, .btn:focus{
    outline: 0;
    border: 0;
    box-shadow: none;
}*/
.NoShadow.HeightAuto.Faqs.card .accordion-item {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 5px;
    border-radius: 5px;
}

.NoShadow.HeightAuto.Faqs.card .accordion-item button.accordion-button {
    background: #f1f1f1;
    color: #000;
    /* background: #f16321; */

}

.sub-nav-bg .form-check.form-switch .form-check-input {
    float: unset;
}


.report-container .accountability-page h4:before,
.report-container .agreement-page h4:before,
.report-container .disclamer-page h4:before,
.report-container .inaction-page h4:before,
.report-container .investment-page h4:before,
.report-container .objective-page h4:before,
.report-container .products-page h4:before,
.report-container .profit-assessment-page h4:before,
.report-container .purpose-page h4:before,
.report-container .reality-page h4:before,
.report-container .roadmap-page h4:before,
.report-container .team-page h4:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 0;
    border: 10px solid #4499d7;
    margin-bottom: 5px;
    margin-right: 10px;
}

.media-video i {
    cursor: pointer;
}

.has-bg-img {
    background-position: center;
    background-size: cover;
}

.bg-purple {
    background-color: hsla(261, 51%, calc(51% * var(--tor-bg-lightness, 1)), var(--tor-bg-opacity, 1)) !important;
}

.bg-blend-multiply {
    background-blend-mode: multiply !important;
}

.h-20rem {
    height: 20rem !important;
}

.shadow {
    box-shadow: var(--tor-shadow-inset) var(--tor-shadow-offsetX, 0rem) calc(0.25rem + var(--tor-shadow-offsetY, 0rem)) 0.625rem rgba(var(--tor-shadow-color), calc(0.15 * var(--tor-shadow-intensity))) !important;
}

.bg-img {
    display: none;
}

.p-7 {
    padding: 5rem !important;
}

address.user-address-info {
    background: #e4f5ec;
    border-radius: 5px;
}

address.user-address-info strong {
    font-weight: 500;
    font-size: 17px;
    color: #000;
}

.payment-method {
    display: inline-block;
}

.payment-method {
    display: inline-block;
    font-weight: 500;
    color: var(--bd-color, #f16321);
    font-size: 17px;
}

#custom-template .u-row-container .u-row,
#custom-template .u-row-container .u-row .u-col.u-col-100 {
    width: 100% !important;
    min-width: 100% !important;
}

#custom-template .u-row-container .u-row .u-col {
    min-width: 100% !important;
}

.section-title {
    background: #444;
    color: #fff;
}

.section-title h5 {
    margin: 0;
}

p.unlocked,
p.locked {
    margin: 0;
    padding: 10px 0px;
}

p.unlocked a,
p.locked a {
    font-size: 16px;
    font-weight: 500;
}

p.unlocked a i.fas.fa-circle.text-success,
p.locked a i.fas.fa-circle.text-muted {
    margin: 0 10px 0 0px;
}

.video-lesson figure {
    padding-bottom: 0 !important;
}

.coure-img {
    min-height: 150px;
    overflow: hidden;
    max-height: 150px;
    position: relative;
}

.pulse a.btn.btn-link.text-left {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.coure-img img {
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
}

/* Let's highlight canvas boundaries */
a.btn.btn-link.text-left .card .card-body .h4.card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lesson-videos.card figure {
    padding-bottom: 0 !important;
}

.lesson-videos.card .se-component.se-video-container.__se__float-none {
    width: 100% !important;
}

.lesson-videos.card .card-title {
    font-size: 20px;
    font-weight: 500;
}

.ProgressDashboard {
    position: relative;
    display: flex;
    justify-content: center;
}

.TotalProgress {
    position: absolute;
    right: 10px;
    color: #000;
    font-size: 14px;
}

.ProgressDashboard .progress,
.ProgressDashboardCoaching .progress {
    height: 20px;
    border-radius: 50px;
}

.ProgressDashboard .progress,
.ProgressDashboardCoaching .progress {
    height: 22px;
    border-radius: 50px;
    width: 100%;
    float: right;
    border: 1px solid #000;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0 3px;
}

.ProgressLabel {
    float: right;
    width: 90%;
}

.ProgressLabel label {
    margin: 0;
    text-transform: uppercase;
}

.ProgressDashboardCoaching .progress {
    position: relative;
}

.ProgressDashboard i {
    font-size: 24px;
    color: #C58524;
    width: 25px;
    text-align: center;
}

.NewCoachDashboard .BarProgress {
    position: relative;
    display: inline-block;
    width: 80%;
}

.NewLeadDialer .BarProgress {
    position: relative;
    display: inline-block;
    width: 100%;
}

.ProgressDashboardCoaching {
    display: flex;
    justify-content: center;
}

.BarProgress i {
    vertical-align: bottom;
    position: absolute;
    bottom: 0px;
}

.ProgressDashboard i.mdi.mdi-account {
    position: relative;
    top: 12px;
}

.BarProgress i.ion.ion-md-call {
    bottom: 0px;
}

.BarProgress i.ion.ion-md-mail {
    bottom: -2px;
}

.BarProgress i.mdi.mdi-comment {
    bottom: -5px;
}

.ProgressDashboard i.mdi.mdi-comment {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    display: inline-block;
    line-height: normal;
}

.BarProgress i.mdi.mdi-currency-usd {
    background: #C58524;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
}

.ProgressDashboardCoaching .BarProgress i {
    color: #C58524;
    font-size: 20px;
}

.ProgressDashboardCoaching .TotalProgress {
    right: 10px;
    top: 0;
}

.ProgressDashboard .progress-bar.bg-primary,
.ProgressDashboardCoaching .progress-bar.bg-primary {
    font-size: 13px;
}

.ProgressDashboardCoaching label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0px 0 1px 0;
    float: left;
    width: 100%;
}

.Campaigns .ProgressDashboardCoaching label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0px 0 1px 0;
    float: left;
    width: 100%;
    font-size: 10px;
    text-transform: uppercase;
}

.VariousCalls .Message.card i.mdi.mdi-comment {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    font-size: 30px;
    line-height: normal;
}

.NewLeadDialer .VariousCalls .Message.card i.mdi.mdi-comment {
    font-size: 28px;
}

.NewLeadDialer .VariousCalls.card i.ion {
    font-size: 30px;
}

.ProgressDashboard.OutboundGoals .progress-bar.bg-primary {
    background-color: #C58524 !important;
    border-radius: 50px;
    height: 16px;
    margin: -1px 0 0 0;
}

.RevenueGoals .ProgressDashboardCoaching .progress-bar.bg-primary {
    background-color: #C58524 !important;
    text-align: left;
    padding-left: 5px;
    height: 16px;
    border-radius: 50px;
}

.DashboardPage .progress-bar.bg-primary {
    text-align: left;
    padding-left: 5px;
}

.system-custom-border {
    /* box-shadow: none;
    position: relative;
    border: 4px solid #d9d9d9;
    border-radius: 10px; */
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #999;
}

.ProgressTrackerImplementation.card.card-body,
.DashboardPage.row .card,
.VariousCalls .card-body,
.Statuses .RecentRecord.RecentLeads.card-body,
.Statuses .StatusListing.card-body,
.RecentRecord.RecentLeads.card-body,
.NewLeadDialer .boxescard.card .card-body,
.VariousCalls.TotalRecord.MyScheduleMain .RecentRecord.MyTasks.card-body,
.CompanyDetail.ClientProfileDetails .card.card-body,
.NoShadow.card.ProfileClientActivity,
.my-coaches .CoachesLists .card-body,
.NewCoachDialer .boxescard.card .card-body,
.NewCoachDialer .VariousCalls.TotalRecord.MyScheduleMain .RecentRecord.MyTasks.card-body,
.EventsCalendar.card-body,
.ClientActionList.card-body {
    /*box-shadow: none;
    position: relative;
    border: 4px solid #d9d9d9;
    border-radius: 10px;*/
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #999;
}

.Message.card {
    background: #55dbde;
    color: #fff;
}

.Email.card {
    background: #ffde59;
    color: #fff;
}

.ReceivedCalls.card {
    background: #fe9150;
    color: #fff;
}

.MissedCalls.card {
    color: #fff;
    background: #3ab6ff;
}

.VariousCalls.card i.ion {
    font-size: 30px;
    width: 100%;
}

/*.VariousCalls.card .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background: #f1f1f1;
    color: #C58524;
}*/
.VariousCalls.card .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background: #f1f1f1;
    color: #C58524;
    box-shadow: 0px 0px 5px 0px #999;
    max-width: 110px;
    margin: auto;
}

.NewCoachDashboard .VariousCalls.card .card {
    max-width: unset;
    margin-top: 5px;
}

.NewCoachDashboard .RecentRecord.RecentLeads .ContactDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 10px;
    background: #fff
}

.NewCoachDashboard .RecentRecord.RecentLeads.card-body li {
    width: 97%;
    padding: 5px;
}

.VariousCalls.TotalRecord.MyScheduleMain .dashboard-calander .card {
    box-shadow: none;
}

.NewCoachDashboard .Statuses .RecentRecord.RecentLeads ul {
    padding-bottom: 10px;
}

.NewCoachDashboard .Statuses .RecentRecord.MyTasks.NewTasks ul li .TaskDescp {
    background: #fff;
}

.NewCoachDashboard .Statuses .RecentRecord.MyTasks.NewTasks ul li {
    text-align: left;
    justify-content: flex-start;
    border: 0;
    margin: 10px 0 15px 10px;
    padding: 0;
}

.NewCoachDashboard .Statuses .RecentRecord.MyTasks.NewTasks ul {
    margin-top: 0;
    max-height: 325px;
}

.VariousCalls .MyScheduleMain.card {
    box-shadow: none;
    max-width: unset;
}


.VariousCalls .active.card .NumberFunc {
    font-size: 34px;
    color: #fff;
    line-height: normal;
    margin: 0;
}

.VariousCalls.card .card .NumberFunc {
    font-size: 28px;
    line-height: normal;
    color: #000;
}

.NewLeadDialer .VariousCalls.card .card .NumberFunc {
    font-size: 28px;
}

.VariousCalls.card .active.card,
.VariousCalls.card .active.card .NumberFunc {
    background: #C58524;
    color: #fff;
}

.AlertBadge {
    position: absolute;
    left: 30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
}

.AlertBadge i {
    color: #fe1616;
    font-size: 22px;
}

.AlertBadge span {
    color: #fff;
    position: absolute;
    z-index: 1;
    top: -2px;
    left: 0;
    right: 0;
    font-size: 17px;
}

.RecentRecord ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 370px;
    overflow: auto;
}

.NewCoachDashboard .RecentRecord ul {
    max-height: 415px;
}

.RecentRecord ul li,
.NewLeadDialer .InboxBusiness ul li {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #999;
    width: 95%;
    margin: auto;
    border-radius: 5px;
}

button.Stats {
    color: #fff;
    width: 100px;
    border: 0;
    border-radius: 8px;
    height: 37px;
    font-size: 20px;
    line-height: 37px;
}

button.Stats.Sucess {
    background: #C58524;
}

button.Stats.Danger {
    background: #960303;
}

button.Stats.Warning {
    background: #f4bc33;
}

.RecentRecord ul li .StatusIcon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    position: relative;
    float: left;
    margin: 0 10px 0 0px;
}

.ContactDescription {
    background: #f1f1f1;
    border-radius: 5px;
    width: 88%;
}

.NewClientDialer .ContactDescription {
    width: auto;
}

.RecentRecord ul li .StatusIcon.Message {
    background: #C58524;
}

.RecentRecord ul li .StatusIcon.Call {
    background: #C58524;
    flex-shrink: 0;
}

.chat-message-right .rounded {
    text-align: right;
}

.RecentRecord ul li .StatusIcon.Email {
    background: #C58524;
}

.RecentRecord ul li .StatusIcon.MissedCalls {
    background: #C58524;
}

.ContactDescription h6.card-title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}

.UserMessage p {
    font-size: 14px;
}

.RecentRecord ul li .StatusIcon.MissedCalls .AlertCalls {
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
    font-size: 8px;
    text-align: center;
    position: absolute;
    top: 5px;
    line-height: 12px;
    right: 6px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 5px;
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #454444;
    border-radius: 5px;
    width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    width: 7px;
}

.RecentRecord.RecentLeads .ContactDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 10px;
}

.RecentRecord.RecentLeads .ContactDescription .ButtonBox button {
    min-height: 35px;
    padding: 0 20px;
    font-size: 15px;
    text-transform: uppercase;
}

.fc td,
.fc th {
    border: 0;
}

.NewCoachDashboard .RecentRecord.RecentLeads {
    min-height: 700px;
}

.RecentRecord.RecentLeads ul {
    max-height: 653px;
}

.RecentRecord.MySchedule .ContactDescription {
    display: flex;
    justify-content: space-between;
    background: transparent;
}

.RecentRecord.MySchedule ul li {
    border-bottom: 1px solid #ddd;
    position: relative;
}

.RecentRecord.MySchedule .ContactDescription h6 {
    font-weight: normal;
}

.RecentRecord.MySchedule ul li .StatusIcon {
    width: 15px;
    height: 15px;
    /* flex: 16px 1; */
    font-size: 12px;
    line-height: 15px;
    margin-top: 4px;
}

.RecentRecord.MySchedule ul li .StatusIcon.Call {
    width: 25px;
    height: 25px;
    line-height: 25px;
    flex: 25px 0;
}

.RecentRecord.MySchedule ul li h6.card-title {
    font-size: 14px;
}

.RecentRecord.MySchedule ul {
    max-height: 295px;
    min-height: 295px;
    overflow: auto;
}



/* .VariousCalls.TotalRecord .card {
    background: var(--bd-color, #f16321);
} */

.VariousCalls.TotalRecord .card .NumberFunc {
    color: #000;
    position: absolute;
    right: 4px;
    top: 0;
}

.IconWithCount i.far.fa-list-alt {
    font-size: 24px;
}

.Statuses .StatusListing .RevenueGoals {
    display: inline-block;
    width: 100%;
}

.IconWithCount span {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    color: #000;
}

.StatusCall {
    width: 100%;
    display: flex;
    background: #f1f1f1;
    align-items: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-between;
}

.RevenueGoals .ProgressDashboardCoaching .progress-bar.bg-primary.warning {
    background-color: #f9e052 !important;
}

.RevenueGoals .ProgressDashboardCoaching .progress-bar.bg-primary.danger {
    background-color: #fa1722 !important;
    color: #fff !important;
}

.StatusHeading {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dashboard-calander {
    position: relative;
}

.Statuses .card-body {
    min-height: 307px;
    max-height: 307px;
}

.Statuses .RecentRecord.RecentLeads ul {
    max-height: 550px;
    min-height: 550px;
}

.Statuses .RecentRecord.MyTasks ul {
    max-height: 220px;
}

.Statuses .RecentRecord.MyTasks ul li {
    border-bottom: 1px solid #ddd;
}

.Statuses .VariousCalls.TotalRecord .card-body {
    min-height: 285px;
    max-height: 285px;
}

.VariousCalls.TotalRecord.MyScheduleMain.TaskManager .card-body {
    min-height: 608px;
    margin: 0;
}

.ClientProfileDetails .VariousCalls.TotalRecord.MyScheduleMain.TaskManager .card-body {
    min-height: 608px;
    margin: 0;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body {
    border: 0;
    background: #fff;
    min-height: auto;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .card-body {
    min-height: 220px;
    max-height: 220px;
}

.BarProgress.ThumbsUp i {
    background: #C58524;
    color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
}

.NewCoachDashboard .VariousCalls.TotalRecord.MyScheduleMain .card-body {
    min-height: 700px;
    max-height: 700px;
}

.NewCoachDashboard .VariousCalls.TotalRecord.MyScheduleMain.TaskManager .card-body {
    min-height: 600px;
    max-height: 600px;
}

.VariousCalls.TotalRecord.MyScheduleMain .RecentRecord.MyTasks.card-body {
    min-height: auto;
    max-height: unset;
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.border-three {
    /* border: 3px solid #d9d9d9; */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #999;
    min-height: 155px;
}

.Statuses .RecentRecord.MyTasks ul li .ContactDescription {
    display: flex;
    justify-content: space-between;
    background: transparent;
    align-items: center;
}

.topnav .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    box-shadow: 0px 0px 20px 0px #555;
}

.NewCoachDashboard .Statuses .RecentRecord.RecentLeads.card-body,
.NewCoachDashboard .Statuses .StatusListing.card-body {
    min-height: 600px;
    max-height: 600px;
}

.Campaigns {
    /*min-height: 530px;*/
    overflow: auto;
    max-height: 530px;
}

.Campaigns .ProgressDashboardCoaching .progress {
    width: 100%;
    position: relative;
    margin-right: 10px;
}

.RecentRecord.MyTasks.card-body .StatusIcon {
    font-size: 16px;
}

/* .company-info .logo .sb-avatar {
    box-shadow: none;
    border: 5px solid #e5e5e5;
}
.company-info .logo .sb-avatar .sb-avatar__text {
    width: 140px !important;
    height: 140px !important;
} */
.NoShadow.custom-scroll.LeftSideUser .company-info .url {
    font-size: 14px;
    word-break: break-all;
}

.NoShadow.custom-scroll.LeftSideUser .company-info .url i {
    font-size: 14px;
    margin: 0 0px 0 5px;
}

.LeftSideUser .compant-action-btn button {
    margin: 5px 2px 0;
    /* box-shadow: 0px 0px 10px 0px var(--bd-color, #3a3b3d); */
    color: #fff;
}

/* .LeftSideUser .accordion-collapse.collapse.show {
    border-top: 0;
    background: #fff;
    height: calc(100vh - 438px);
    overflow: auto;
} */
.LeftSideUser .AboutCard {
    height: calc(100vh - 362px);
    overflow: auto;
}

.email .modal-head.top-heading,
.note .modal-head.top-heading,
.sms .modal-head.top-heading,
.call .modal-head.top-heading,
.rvm .modal-head.top-heading {
    border-radius: 0.4rem 0.4rem 0 0;
}

.client-actions button.disableded {
    opacity: .75;
    cursor: not-allowed;
}

.client-actions button.disableded i.fas.fa-ban {
    position: absolute;
    font-size: 10px;
    left: 9px;
    top: 0px;
    background: #fff;
    border-radius: 10px;
}

.client-actions button.btn.btn-md {
    margin: 0 3px;
    width: 22px;
    text-align: center;
    height: 22px;
    padding: 0;
    position: relative;
    font-size: 12px;
}

/* .client-actions button.btn.disableded {
    
} */
.client-actions button.btn.btn-danger.btn-md.disableded i.fas.fa-phone-alt.client-list {
    margin: 0;
}

.client-actions button.btn.btn-md.disableded.email-disable {
    color: #a4a4a5;
    background: #e7e7e7;
    cursor: not-allowed;
    padding: 0;
}

.client-actions button.btn.btn-md.disableded.phone-disable {
    color: #FA6262;
    background: #f9dada;
    font-size: 13px;
    padding: 3px;
    cursor: not-allowed;
}

.client-actions button.btn.btn-md.disableded.sms-disable {
    color: #fff;
    background: #f44336;
    font-size: 12px;
    padding: 1px 3px;
    cursor: not-allowed;
}

.client-actions {
    text-align: center;
}

.client-actions button.btn.btn-danger.btn-md.disableded.top-btns.phone-block {
    color: #fff;
    background: #f44336;
    font-size: 13px;
    padding: 3px;
    cursor: not-allowed;
}

.client-actions button.btn.btn-md.phone-active,
.client-actions button.btn.btn-md.file-active {
    font-size: 12px;
    background: #dcffdb;
    color: #6bd566;
    padding: 1px 3px;
}

.client-actions button.btn.btn-md.email-active {
    background: #fff4d2;
    color: #FBBC04;
}

.client-actions button.btn.btn-danger.btn-md.disableded.email-block {
    color: #FA6262;
    background: #f9dada;
    cursor: not-allowed;
    border-color: #f9dada;
}

button.btn.btn-md.sms-active {
    background: #daebfd;
    color: #51A3FE;
    font-size: 12px;
}

.NoShadow.custom-scroll.LeftSideUser.card {
    background: transparent;
    box-shadow: none;
}

.NoShadow.custom-scroll.LeftSideUser.card .user-profile-pic-area {
    width: 100%;
    /* margin-top: 5px; */
}

.NoShadow.custom-scroll.LeftSideUser.card .card {
    margin-bottom: 5px;
    border-radius: 0;
}

.NoShadow.custom-scroll.LeftSideUser .AboutCard.card {
    min-height: calc(100vh - 407px);
    max-height: calc(100vh - 407px);
    margin: 0;
}

.LeftSideUser .AboutCard .details {
    max-height: 100%;
}

.NoShadow.custom-scroll.LeftSideUser.card .card:last-child {
    margin-bottom: 0;
    background: #fff;
    height: calc(100vh - 447px);
}

.form-clients {
    width: 100%;
}

p.mr-5.TotalCount {
    max-width: 220px;
    display: inline-block;
    min-width: 170px;
}

.DropDownSelecter {
    display: inline-block;

}

.DropDownSelecter button {
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem !important;
    font-size: 0.8125rem !important;
}

/* .ContentActionForm {
    width: 70%;
    float: left;
}
.ContentActionSearch {
    width: 30%;
    float: left;
} */
.FormInputs {
    display: flex;
    min-width: 200px;
    position: relative;
}

.ContentActionForm .btns {
    display: inline-block;
    margin: 0 0 0 5px;
}

.SelectTags {
    position: relative;
    min-width: 210px;
    z-index: 7;
    margin: 0 5px;
    float: left;
}

.SelectTags .css-2b097c-container {
    width: 100%;
    display: inline-block;
    position: absolute;
}

.Client.One .css-g1d714-ValueContainer {
    max-height: 101px;
    overflow: auto;
}

.SelectTags input.form-control,
.ContentActionForm .btns button {
    height: 38px;
}

.full-width {
    width: calc(100% - 0px);
    display: flex;
}

.SelectTags .css-yk16xz-control {
    min-height: 33.5px !important;
}

.SelectTags .css-1hb7zxy-IndicatorsContainer {
    height: 33.5px;
}

button.waves-effect.border-0 {
    background: transparent;
}

.blockdays {
    background: #ffc8c8 !important;
}

.appointment-calander .fc .fc-daygrid-day-frame {
    min-height: 144px;
}

.appointment-calander .fc-daygrid-event {
    white-space: inherit;
    text-align: left;
}

.appointment-calander .fc-direction-ltr .fc-daygrid-event.fc-event-end {
    font-size: 10px !important;
}

.appointment-calander .bg-completed {
    background: #009e0f;
}

.appointment-calander .bg-busy {
    background: #bd3539;
}

.appointment-calander .bg-expired {
    background: #a9a9a9;
}

.appointment-calander .bg-not-available {
    background: #6e6e6e;
}

.appointment-calander .bg-appointment {
    background: #12781c;
}

.appointment-calander .fc-event {
    margin: 0px 7px;
    padding: 0px !important;
    min-height: 17px;
    font-size: 10px;
}

span.event-label {
    width: 100%;
    display: flex;
    vertical-align: top;
}

span.event-label label {
    width: 30px;
    height: 100%;
    vertical-align: top;
    margin: 0;
    float: left;
    line-height: normal;
    font-weight: normal;
}

span.event-label p {
    float: left;
    width: 78%;
    /* text-transform: capitalize; */
    line-height: normal;
}

.RecentRecord.MySchedule ul li .CurrentWeek {
    position: absolute;
    top: -2px;
    left: 12.5px;
    font-size: 12px;
    line-height: normal;
    width: 15px;
    text-align: center;
}

.user-profile-pic-area .CompanyProfileBG.card,
.user-profile-pic-area .UserProfileBG.card {
    background-image: url(../images/company-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10px;
    margin-bottom: 0px;
}

.user-profile-pic-area .CompanyProfileBG.card .px-0.card-body,
.user-profile-pic-area .UserProfileBG.card .px-0.card-body {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
}

.LeftSideUser .details .accordion-item {
    border: 0;
    display: inline-block;
    width: 100%;
    background: #fff;
}

.LeftSideUser .details .accordion-item h2.accordion-header {
    background: #f2f1f9;
    margin-bottom: 2px;
}

.user-profile-pic-area .CompanyProfileBG.card .company button.btn.btn-link.btn-md.pull-right,
.user-profile-pic-area .UserProfileBG.card .company button.btn.btn-link.btn-md.pull-right {
    position: absolute;
    right: 5px;
    padding: 0;
    top: 5px;
}

.user-profile-pic-area .CompanyProfileBG.card button.btn.btn-link.btn-md.pull-right {
    top: 16px;
}

.NoShadow.custom-scroll.LeftSideUser {
    background: #fff;
    height: calc(100vh - 120px);
    box-shadow: none;
    border: 2px solid #e2e2e2;
}

.Statuses .RecentRecord.MyTasks ul li.no-record,
.RecentRecord.MySchedule ul li.no-record {
    border: 0;
}

.Statuses .RecentRecord.MyTasks ul li.no-record .ContactDescription,
.RecentRecord.MySchedule ul li.no-record .ContactDescription {
    background: #f1f1f1;
    justify-content: center;
}

.RevenueGoals.no-record {
    background: #f1f1f1;
    border-radius: 5px;
    width: 100%;
    padding: 0.25rem
}

#layout-wrapper .img-page button.btn.btn-link {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    min-height: auto;
    height: 200px;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc !important;
    border-radius: 0;
}

#layout-wrapper .img-page button.btn.btn-link img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

#layout-wrapper .img-page button.btn.btn-link h3.title-page {
    background: rgba(0, 0, 0, .7);
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    transition-delay: 1s;
    padding: 10px;
}

#layout-wrapper .img-page button.btn.btn-link:hover h3.title-page {
    display: flex;
}

#layout-wrapper .active.img-page button.btn.btn-link {
    border: 0 !important;
}

#layout-wrapper .img-page {
    border: 5px solid transparent;
}

#layout-wrapper .active.img-page {
    border: 5px solid var(--bd-color, #f16321);
    box-shadow: 0px 0px 10px 0px #999;
}

.card.ThemeCard {
    background-color: #fff;
    box-shadow: none;
    position: relative;
}

.card.ThemeCard .card-header {
    background: transparent;
}

#layout-wrapper .card.ThemeCard .card-body {
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px 0px #999;
    height: 250px;
    overflow: hidden;
}

.card.ThemeCard button.waves-effect.waves-light.btn.btn-secondary.text-white.bg-secondary {
    width: 70px;
    background-color: var(--bd-color, #f16321) !important;
    border-color: var(--bd-color, #f16321) !important;
    margin: 10px auto 0;
    font-size: 16px;
}

.ThemeTitleButton {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    transition-duration: 2s;
}

.card.ThemeCard:hover .ThemeTitleButton {
    display: flex;
    transition-duration: 2s;
}



.activity-bubble:after {
    width: 19px;
    height: 1px;
    content: '';
    position: absolute;
    background: #c2c1c1;
    left: -19px;
    z-index: 1;
}

.activity-bubble {
    display: flex;
    align-items: center;
}

.activity-bubble:before {
    width: 30px;
    height: 30px;
    position: absolute;
    background: #F87B5B;
    left: -45px;
    border-radius: 50px;
    box-shadow: 0 0 1px #ccc;
    z-index: 2;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    content: "";
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;

}

.activity-bubble.call:before {
    background-image: url(../images/profile-status/call.png);
    background-color: #34c934;
}

.activity-bubble.note:before {
    background-image: url(../images/profile-status/note.png);
    background-color: #40b7ad;

}

.activity-bubble.task:before {
    background-image: url(../images/profile-status/task.png);
    background-color: #00a03d;
}

.activity-bubble.sms:before,
.activity-bubble.mms:before {
    background-image: url(../images/profile-status/sms.png);
    background-color: #2196f3;

}

.activity-bubble.email:before {
    background-image: url(../images/profile-status/email.png);
    background-color: #77a05e;

}

.activity-bubble.other:before {
    background-image: url(../images/profile-status/other.png);
    background-color: #ff6900;
}

.activity-bubble.contract:before {
    background-image: url(../images/profile-status/contract.png);
    background-color: #941717;
}

.activity-bubble.rvm:before {
    background-image: url(../images/profile-status/rvm.png);
    background-color: #f05282;
    background-size: 20px;
}

.activity-bubble.voice:before {
    background-image: url(../images/profile-status/voicemail.png);
    background-color: #57487e;
    background-size: 11px;
}

.activity-bubble.deal:before {
    background-image: url(../images/profile-status/deal.png);
    background-color: #ff5b62;
    background-size: 25px;
}

button.waves-effect.table-btn {
    border: 0;
    background: transparent;
    padding: 0;
}

#layout-wrapper .card .card-body table th,
#layout-wrapper .card .card-body table td {
    vertical-align: middle;
    /* text-align: right; */
    text-align: left;
    border-right: 0;
}

.Activity.card {
    /* background-image: url(http://192.168.5.88:5000/static/media/DashboardBG2.01c77df….png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 0;
    border-radius: 15px;
}

/* .Activity.card:before {
    content: "";
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .6;
} */

/* .LeftSideUser.Com .accordion-collapse.collapse.show {
    height: calc(100vh - 391px);
} */
.tab-pane.secondary-tabs ul.nav.nav-tabs {
    padding-top: 0;
}

.secondary-tabs ul.nav.nav-tabs li.nav-item a.nav-link {
    background: transparent;
    color: #555;
    border: 0;
}

.secondary-tabs ul.nav.nav-tabs li.nav-item a.active.nav-link {
    text-decoration: underline !important;
    color: #7a6fbe;
}

.details.ProflieActions button.btn.btn-link {
    text-align: right;
}

.ClientDialer .AboutCard {
    height: calc(100vh - 403px);
    overflow: auto;
}

.CompanyDialer .AboutCard {
    height: calc(100vh - 440px);
}

@media screen and (max-width: 7000px) and (min-width: 1700px) {
    .CompanyDialer .AboutCard {
        height: calc(100vh - 440px);
    }

    .ClientDialer .AboutCard {
        height: calc(100vh - 380px);
        overflow: auto;
    }
}

@media screen and (max-width: 1092px) and (min-width: 540px) {
    .CompanyDialer .AboutCard {
        height: calc(100vh - 469px);
    }
}

@media screen and (max-width: 1365px) and (min-width: 1093px) {
    .CompanyDialer .AboutCard {
        height: calc(100vh - 410px);
    }
}



.ClientDetails .AboutCard {
    height: calc(100vh - 243px);
}

.ActivityTitle p {
    color: #7d98b5;
}

.ActivityTitle {
    text-transform: capitalize;
    width: 70%;
}

.Training {
    height: calc(100vh - 230px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 15px 0 0px;
}

.Activity.card .accordion-item button.btn.btn-link {
    text-align: left;
    max-width: 200px;
    padding: 0;
    min-width: 200px;
}

.page-content.dialer .CompanyDetail .col-md-3.text-end {
    padding-right: 15px;
}

.deal-pipeline.remove-arrow .row {
    align-items: center;
}

.deal-pipeline.remove-arrow button.accordion-button {
    padding: 0.7rem 1.25rem;
}

.table> :not(caption)>*>* {
    padding: 0.2rem 0.5rem;
}

/* .btn-sm {
    padding: 0.05rem 0.3rem;
} */
.page-content.dialer {
    height: auto;
}

.proposal-grid {
    margin-left: 0 !important;
}

.page-content.dialer .CompanyDetail .deal-pipeline.remove-arrow .col-md-3.text-end button.btn.btn-link {
    min-width: auto;
    max-width: none;
    margin: 0 0 0 15px;
}

.activity-bubble.rvm .ActivityTitle,
.activity-bubble.email .ActivityTitle {
    width: 65%;
}

.CompanyDetail.ClientDetails .tab-content .tab-pane .remove-arrow {
    grid-template-columns: 62% auto;
}

.CompanyDetail.ClientDetails .tab-content .tab-pane .remove-arrow button.btn.btn-link {
    max-width: none;
    min-width: auto;
}

.CoachesDrop {
    position: relative;
    margin-bottom: 10px;
    z-index: 2;
}

.CoachesDrop .DropDownCoh {
    position: absolute;
    width: 100%;
}

.CoachesDrop.Criteria {
    top: -20px;
    margin: 0;
}

.PipeLineDrop {
    position: relative;
}

.CardImages {
    max-height: 190px;
    overflow: hidden;
}

.training-view .card-body p.card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.training-view .card-body .h6.card-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

a.waves-effect.TemplateCard.btn.btn-light {
    width: 100%;
    margin-bottom: 30px;
}

.TemplateCard .card-body {
    position: absolute;
    background: rgba(0, 0, 0, .7);
    left: 0;
    right: 0;
    color: #fff;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

.TemplateCard .card {
    margin: 0;
}

#sidebar-menu.ProposalViewBar a.dropdown-item {
    padding: 0.35rem 0.5rem;
}

#sidebar-menu.ProposalViewBar ul li a i {
    min-width: 1.2rem;
    float: left;
    line-height: normal;
}

.simplebarSide .simplebar-content-wrapper {
    overflow: hidden !important;
}

.simplebarSide .metismenu li span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
    display: inline-block;
}

.green-border {
    border-top: 5px solid #60c560;
}

.orange-border {
    border-top: 6px solid #f0ad4e;
}

.Activity.card .tab-content .PrimaryTabs .details .accordion-collapse p button.btn.btn-sm.btn-link {
    min-width: auto;
    margin: 3px 5px 0 5px;
    max-width: none;
    float: left;
}

table#as-react-datatable tr td a.waves-effect {
    width: 100%;
    margin: 0;
}

.CompanyProfileBG.card .company {
    min-height: 150px;
}

.UserProfileBG.card .company {
    min-height: 100px;
}

.CompanyDetail.ClientProfileDetails .LeftSideUser {
    background: transparent;
    border: 0;
    position: sticky;
    top: 0;
    z-index: 8;
}

.CompanyDetail.ClientProfileDetails.NewLeadDialer .LeftSideUser {
    background: transparent;
    border: 0;
    position: fixed;
    top: 82px;
    z-index: 8;
    max-width: 23vw;
    min-width: 23vw;
}

.CompanyDetail.ClientProfileDetails.NewLeadDialer .LeftSideUser .AboutCard {
    background: #fff;
    /* border: 2px solid #e2e2e2; */
    height: calc(100vh - 340px);
}

/*.CompanyDetail.ClientProfileDetails .LeftSideUser .AboutCard {
    background: #fff;
     border: 2px solid #e2e2e2; 
    height: calc(100vh - 336px);
}*/
.CompanyDetail.ClientProfileDetails .LeftSideUser .AboutCard {
    background: #fff;
    /* border: 2px solid #e2e2e2; */
    height: calc(100vh - 381px);
}

.UserProfileBG.card {
    box-shadow: none;
    margin: 0;
}

h6.ProfileSecHeading {
    background-color: #eee;
    color: #000;
    margin: 0;
}

.pipeline ul li:hover {
    cursor: pointer;
    color: var(--bd-color, #f16321);
}

.button-items .btn {
    margin-bottom: 0;
    margin-left: 8px;
}

.MainTZone {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 38px;
    z-index: 2;
}

.MainTZone .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.MainTZone.MainZone .css-2b097c-container {
    z-index: 2;
}

.UsageType {
    position: relative;
    width: 100%;
}

.UsageType .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.UsageType .css-2b097c-container .css-yk16xz-control {
    min-height: 25px;
    height: 33px;
}

.MainTZone.UsageGroup {
    z-index: 999;
}

.EventReminder {
    z-index: 3;
}

.EventReminder.One,
.Assessment.One,
.Task.One,
.Client.One,
.LeadsImport.One,
.Campaign.One,
.Campaign.Two,
.ClientDetails.One,
.ClientDetailsUpdate.One,
.SendSMS.One,
.SendSMS.One,
.MainTime.One {
    z-index: 7;
}

.EventReminder.Two {
    z-index: 6;
}

.EventReminder.Three {
    z-index: 5;
}

.ProfileInfo {
    padding: 0;
    list-style: none;
    margin: 0;
    text-align: center;
}

.ProfileInfo {
    font-size: 16px;
}

.ProfileInfo {
    min-width: 70px;
    display: inline-block;
}

ul.ProfileInfo .compant-action-btn {
    margin-top: 10px;
}

.ClientProfileDetails .logo {
    width: 85px;
    height: 85px;
    text-align: center;
    border-radius: 100%;
    margin: 0 auto;
    border: 2px solid #ccc;
    line-height: 38px;
    padding: 2px 0px 0 1px;
}

.ClientProfileDetails .logo img {
    width: 100%;
    border-radius: 100%;
}

.company-info .info.ProfileInfo {
    text-align: center;
    width: 100%;
    margin-top: 15px;
}

.CompanyDetail.ClientProfileDetails .LeftSideUser .UserProfileBG.card {
    margin: 0;
    border: 2px solid #e2e2e2;
}

.ClientProfileDetails h2.accordion-header button.accordion-button {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-shadow: none;
    background: var(--bd-color, #f16321);
    padding: 7px 10px;
}

.ClientProfileDetails h2.accordion-header button.accordion-button.collapsed {
    background: #f2f1f9;
    color: #000;
}

.ClientProfileDetails .accordion-body .info span {
    width: 100px;
    display: inline-block;
    font-size: 13px;
    vertical-align: top;
    text-transform: capitalize;
}

.ClientProfileDetails .accordion-body .info p {
    width: 62%;
    display: inline-block;
    padding: 0 0 0 10px;
    word-break: break-all;
    font-size: 12px;
}

.ClientProfileDetails .accordion-body .info {
    padding: 0;
    vertical-align: middle;
}

.ClientProfileDetails .accordion-body .info:nth-child(even) {
    background: #f2f6f9;
}

.accordion-collapse .info.info {
    padding-left: 0px;
    background: #fff;
}

.ClientInfor h6 {
    margin: 0;
    background: #f2f6f9;
}

.ClientProfileDetails .accordion-body .BackGroundWhite {
    background: #fff;
}

.pl-5 {
    padding-left: 2rem;
}

.UserActivityDetails ul.nav.nav-tabs {
    display: flex;
    border: 0;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 290px;
}

.UserActivityDetails ul.nav.nav-tabs li.nav-item {
    width: 95px;
    position: relative;
}

.UserActivityDetails ul.nav.nav-tabs li.nav-item a.nav-link {
    min-width: 4rem;
    min-height: 60px;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
    border-radius: 5px;
    border: 0;
    font-size: 1.2em;
    flex-direction: column;
    background: transparent;
    padding: 8px 0 !important;
}


.UserActivityDetails ul.nav.nav-tabs li.nav-item:last-child a.nav-link {
    margin: 0;
}

.UserActivityDetails .secondary-tabs ul.nav.nav-tabs li.nav-item a.nav-link {
    min-width: auto;
    min-height: auto;
    margin: 0;
    padding-bottom: 0 !important;
    padding: 5px 15px !important;
}

.UserActivityDetails .secondary-tabs ul.nav.nav-tabs {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: auto;
    position: static;
}

.UserActivityDetails .secondary-tabs ul.nav.nav-tabs li.nav-item {
    display: inline-block;
}

.UserActivityDetails li.nav-item a.nav-link i {
    font-size: 22px;
    margin: 0 20px 0 0px;
}

.UserActivityDetails ul.nav.nav-tabs li.nav-item a.active.nav-link {
    /* background: #fff; */
    position: relative;
}

.UserActivityDetails .secondary-tabs ul.nav.nav-tabs li.nav-item a.active.nav-link {
    background: var(--bd-color, #f16321);
    color: #fff;
    text-decoration: none !important;
}

.UserActivityDetails .secondary-tabs ul.nav.nav-tabs li.nav-item a.active.nav-link {
    background: var(--bd-color, #f16321);
    color: #fff;
    text-decoration: none !important;
    padding: 5px 15px !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ProfileIcons {
    height: 70px;
    width: 70px;
    margin: 0;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    align-items: center;
    line-height: 60px;
    display: flex;
    justify-content: center;
}

.UserActivityDetails ul.nav.nav-tabs li.nav-item a.active.nav-link span {
    color: #000;
    width: 100%;
    text-align: center;
}

.OnlineOffline {
    position: absolute;
    left: 10px;
    font-size: 12px;
    top: 5px;
}

span.OnlineIcon,
span.Offline {
    width: 8px;
    height: 8px;
    background: #46af69;
    border-radius: 50%;
    margin: 5px 3px 0 0px;
    float: left;
}

span.OfflineIcon {
    background: red;
}

.page3.ProfileClient {
    width: 74.3%;
}

.ProfileIcons img {
    width: 80%;
}

.ProfileIcons {
    background: #454444;
    position: relative;
}

.ProfileIcons.Video .VideoUploads {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
}

.ProfileIcons.Video .VideoUploads img {
    width: 70%;
}

.UserActivityDetails ul.nav.nav-tabs li.nav-item a.nav-link span {
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}

.settingArea button {
    height: auto;
}

.ClientProfileDetails .LeftSideUser ul.nav.nav-tabs {
    padding: 5px 0 0 0;
    border: 0;
}

.settingArea {
    position: absolute;
    right: 5px;
    top: 3px;
}

.client-chat-area .card {
    border-radius: 0;
    margin: 0;
}

.client-chat-area .card-body {
    padding: 0;
}

.client-chat-area .chat-message-left,
.client-chat-area .chat-message-right {
    margin-right: auto;
    flex-wrap: wrap;
}

.client-chat-area .chat-message-right {
    margin-right: 0;
}

.client-chat-area .chat-message-right .small.text-nowrap {
    text-align: right;
}

.client-chat-area .small.text-nowrap {
    width: 100%;
}

.client-chat-area .chat-message-left .bg-light.rounded,
.client-chat-area .chat-message-right .bg-light.rounded {
    display: flex;
    column-gap: 10px;
}

.client-chat-area .chat-message-right .bg-light.rounded {
    flex-direction: row-reverse;
}

.client-chat-area .chat-message-left .bg-light.rounded p,
.client-chat-area .chat-message-right .bg-light.rounded p {
    padding-top: 5px;
    margin: 0;
    line-height: normal;
}

.client-chat-area .chat-messages {
    height: calc(100vh - 334px);
}

.client-chat-area {
    overflow: auto;
    border: 2px solid #e2e2e2;
}

.UserActivityDetails .page3 .nav-tabs {
    margin: 0;
}

.ExistingAttachment {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}

.Activity.card .ProfileClient .accordion-item button.btn.btn-link {
    text-align: left;
    max-width: 260px;
    padding: 0;
    min-width: 260px;
}

.page3.ProfileClient .accordion-head {
    grid-template-columns: 74% auto;
}

.ExistingAttachment td {
    vertical-align: middle;
}

.company-info .info.ProfileInfo h5 {
    margin: 0;
    font-size: 14px;
}

.SystemNotification .notification-item .media {
    padding: 5px 10px;
}

.SystemNotification .avatar-xs {
    height: 20px;
    width: 20px;
}

.assessment-vertical-menu {
    height: calc(100vh - 135px);
    position: sticky;
    top: 0;
}

.simplebar-mask #sidebar-menu {
    padding-top: 0;
}

section.top-section.Assessments {
    background: transparent;
}

.SummaryAssessment .survey.card.card-body {
    max-height: calc(100vh - 200px);
    padding-left: 0;
    margin-left: -1px
}

.mr-30 {
    margin-right: 30px;
}

.color-success {
    color: #00782b;
    font-weight: 700;
}

.card {
    margin-bottom: 15px;
}

.TimeZoneMain {
    position: relative;
    width: 100%;
    float: left;
    min-height: 30px;
}

.TimeZoneMain .css-2b097c-container {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.TimeZoneMain.MainTime .css-2b097c-container {
    z-index: 2;
}

/* .TimeZoneMain .css-2b097c-container .css-yk16xz-control {
    min-height: 25px;
    height: 33px;
} */
.Pipline span.pipelint-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 194px;
    margin: 0;
    display: inline-block;
    vertical-align: bottom;
}

.training-view .bg-purple h5 {
    line-height: normal;
}

.training-view .page-title-box {
    padding-bottom: 0;
}

.NameBtns {
    min-width: 130px;
    text-align: left;
}

.action-controls input[type="checkbox"] {
    float: left;
    margin: 1px 5px 0 0px;
    width: 18px;
    height: 18px;
}

ul.navbar-nav.client-nav-bar {
    flex-direction: row;
    line-height: 50px;
}

ul.navbar-nav.client-nav-bar li.nav-item .nav-link {
    color: #fff;
    background: transparent;
    border: 0;
}



.from-coach .room {
    height: calc(100vh - 172px);
    position: relative;
}

.pull-right.video-button .page3.w-75.slide-in {
    height: calc(100vh + 5px);
}

.from-coach .remote-participants .participant video {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 51px);
}

.room {
    height: calc(100vh - 118px);
    position: relative;
}

.video-control {
    position: absolute;
    bottom: 20px;
    width: 190px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    z-index: 999999;
}

.video-control button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0;
    font-size: 22px;
}

.local-participant video {
    height: 100px;
    display: inline-block;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    z-index: 9999999999;
    top: 10px;
}

.remote-participants {
    position: relative;
    z-index: 1;
}

.remote-participants .participant video {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 105px);
}

.page2.video-call {
    height: calc(100vh - 122px);
}

.page3.video-chat-call.ProfileClient {
    height: calc(100vh + 5px);
}

button.call-drop {
    background: red;
    color: #fff;
    transform: rotate(135deg);
}

#sidebar-menu ul li a,
.metismenu li button.dropdown-btn {
    background: #c58524;
    margin: 0 0 0 0;
    padding-left: 10px;
    padding-right: 10px;
    color: #000000;
    font-size: 17px;
}
#sidebar-menu ul li a i {
    display: inline-block;
    min-width: 1.60rem;
    padding-bottom: .125em;
    font-size: 1rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #000000;
    transition: all .4s;
}
button.dropdown-btn.active, #sidebar-menu ul li a.active
{
    color: #000000;
    background: #d5d2d2;
}
[data-simplebar] {
    background: #c58524;
    z-index: 9;
}
.metismenu li button.dropdown-btn {
    background: var(--bd-color, #f16321);
    color: #fff;
}

.metismenu li .dropdown-container .survey-filled {
    background: var(--bd-color, #f16321) !important;
    color: #fff !important;
    opacity: .6;
}

.metismenu li.mm-active li.mm-active {
    background: #746e6e;
}
.sub-menu mm-collapse mm-show{
    background: aliceblue;
}
.simplebar-mask #sidebar-menu.CoachSidebar li a.has-arrow.waves-effect.mm-active {
    background: #fff;
    color: #fff !important;
}

.simplebar-mask #sidebar-menu.CoachSidebar li a {
    text-align: left;
}

.simplebar-mask #sidebar-menu.CoachSidebar li a.active {
    color: #fff !important;
}

.simplebar-mask #sidebar-menu.CoachSidebar li a:hover,
#sidebar-menu .ResourcesSidebar li a:hover {
    background: var(--bd-color, #f16321);
}

.page-content.dialer .page3.contract-details {
    height: calc(100vh + 5px);
}

ul#side-menu.metismenu.list-unstyled.CreditsPage a {
    text-align: left;
}

ul#side-menu.metismenu.list-unstyled.CreditsPage a.dropdown-item.active,
#sidebar-menu .ResourcesSidebar li a.active {
    color: #fff !important;
}

#sidebar-menu ul li ul.sub-menu li a {
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
    background: #fff;
}

#sidebar-menu .ResourcesSidebar li a {
    text-align: left;
}

.sun-editor {
    position: relative;
    z-index: 1;
}

.page2.video-chat-call.CoachPickup {
    width: 25%;
}

.CalculatorArea {
    height: 100%;
    display: flex;
    align-items: center;
}

.ProfileImpact {
    position: relative;
    display: inline-block;
    width: 566px;
    height: 200px;
    margin: 0 auto;
}

.ProfitImpactChart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #6ca14c;
    border: 15px solid var(--bd-color, #f16321);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ProfitImpactChart h3 {
    color: #fff;
    width: 100%;
    display: inline-block;
    font-weight: 400;
}

.half-circle {
    width: 227px;
    height: 115px;
    border-top-left-radius: 145px;
    border-top-right-radius: 145px;
    border: 10px solid #000;
    border-bottom: 0;
    transform: rotate(90deg);
    position: absolute;
    top: 43px;
    left: 45px;
}

.Impacts .PointIcon {
    background: var(--bd-color, #f16321);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;

}

.Impacts._0 .PointIcon {
    left: 157px;
    top: 0;
}

.Impacts._1 .PointIcon {
    left: 202px;
    top: 45%;
}

.Impacts._2 .PointIcon {
    left: 155px;
    bottom: 0;
}

.Impacts:after {
    width: 70px;
    height: 3px;
    background: var(--bd-color, #f16321);
    content: "";
    position: absolute;
    z-index: 1;
}

.Impacts._0:after {
    left: 175px;
    top: 9px;
}

.Impacts._1:after {
    left: 220px;
    top: 50%;
    width: 65px;
}

.Impacts._2:after {
    left: 175px;
    bottom: 3%;
}

.OperationsImpact {
    position: absolute;
    display: flex;
    align-items: center;
    left: 245px;
}

/* .PercentCounter {
    width: 70px;
    height: 70px;
    border: 2px solid #000;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin: -15px 0 0 0;
} */
.PercentCounter .PercentNumber {
    width: 55px;
    height: 55px;
    border: 3px solid #C58524;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C58524;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    position: relative;
    z-index: 5;
}

.PackegeName {
    background: #C58524;
    right: 0;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 25px 0 0 -12px;
    position: relative;
    z-index: 1;
    border-radius: 0 15px 15px 0;
}

.PackegeName h6 {
    margin: 0 5px 0 10px;
    color: #fff;
}

.survey section.breakthrough {
    width: 85%;
    margin: 0 auto;
    position: relative;
    top: 25px;
    z-index: 1;
    min-height: 35px;
    border-radius: 5px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.btn.btn-primary.saveBtn {
    width: 100%;
}

/* .survey section.breakthrough h5 {
    color: #c5cad0;
} */
.survey .card.card-body.Breakdown {
    min-height: 345px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.BGYellow {
    background: #f4bc33;
    color: #fff;
    padding: 5px 20px;
    align-items: center;
}

.PackegeName i.fa.fa-arrow-circle-up {
    color: #fff;
    font-size: 16px;
    margin: 0 0 0 10px;
}

.OperationsImpact._0 {
    top: -25px;
}

.OperationsImpact._1 {
    top: 33%;
    left: 285px;
}

.OperationsImpact._2 {
    bottom: -30px;
}

.ShowTotalRev {
    text-align: right;
}

.ShowTotalRev h3 {
    color: #fff;
    width: auto;
    float: right;
    background: #454444;
    padding: 1px 5px;
    font-size: 16px;
    border-radius: 0 0 5px 5px;
    margin: 0 10px 0 0px;
    min-width: 60px;
    text-align: center;
}

.bars-icon {
    position: absolute;
    right: 5px;
    top: 0;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #f5bd32;
    bottom: auto;
}

.CalculationResult {
    display: flex;
    justify-content: space-between;
    width: 62%;
    margin: 0 auto;
}

.Current {
    width: 70%;
    display: inline-block;
}

.Current.Projected {
    width: 100%;
}

/* .fill-survey-question {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
} */
.fill-survey-question {
    min-height: calc(100vh - 420px);
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}

.fill-survey-question button {
    background: #C58524;
    color: #fff;
    border: 0;
    width: 100%;
    padding: 5px 0;
    cursor: default;
}

.Current .ShowCalc {
    width: 20px;
    height: 20px;
    background-color: #454444;
    float: left;
    margin: 0 10px 0 0px;
}

.survey.card.card-body table.table tbody tr td:first-child {
    background: #C58524;
    color: #fff;
}

.survey.card.card-body table.table tbody tr:nth-child(odd) td:first-child a {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 85%; */
    float: left;
    font-size: 12px;
    margin: 0 10px 0 0px;
}

.survey.card.card-body table.table tbody tr:nth-child(odd) td:first-child span {
    color: #fff;
    font-size: 12px;
}

.survey.card.card-body table.table tbody tr:nth-child(even) td:first-child a {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    float: left;
}

.Current.Projected .ShowCalc {
    background-color: #C58524;
}

.training-view .pulse .card-body {
    min-height: 152px;
}

.HeadshotImg {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5b626b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    padding-left: 115px;
}

.HeadshotImg label {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 0.75rem;
    width: 100%;
    right: 0;
}

.HeadshotImg label span {
    background: #f1f1f1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 31px;
    line-height: 31px;
    padding: 1px 14px;
    border-radius: 0.25rem 0 0 0.25rem;
}

.ImagePreview img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.centerr-logo {
    background: #fff;
    padding-bottom: 5px;
}


.logo-centerrr {
    position: absolute;
    left: -30px;
    right: 0;
    margin: auto;
}

.toogle-sales {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 30px;
}

.toogle-sales .form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

ul.summary-report.h-100px {
    height: 100px;
}

@media (min-width: 1200px) {
    .proposal-grid {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 1365px) and (min-width: 1200px) {
    .client-search-bar {
        right: auto;
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .app-search.search {
        width: 49%;
    }

    .Statuses .RecentRecord.RecentLeads.card-body,
    .Statuses .StatusListing.card-body {
        max-height: 608px;
        min-height: 608px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .topnav {
        height: auto;
    }

    .Campaigns,
    .Statuses .card-body,
    .Statuses .RecentRecord.RecentLeads.card-body,
    .Statuses .StatusListing.card-body,
    .RecentRecord.MySchedule ul,
    .RecentRecord.RecentLeads {
        min-height: auto;
    }

    .dropdown-menu {
        right: 0;
    }

    .app-search.search {
        width: 100%;
    }

    .RecentRecord.RecentLeads {
        min-height: calc(100vh + 296px);
    }

    .MyScheduleMain {
        width: 48.8%;
        display: inline-block;
    }

    .display-flex {
        display: flex;
    }

    .Statuses .RecentRecord.RecentLeads.card-body,
    .Statuses .StatusListing.card-body {
        max-height: 608px;
        min-height: 608px;
    }

    .RecentRecord.MySchedule {
        height: calc(100vh + 4px);
    }

    .RecentRecord {
        height: calc(100vh + 44px);
    }

}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .topnav {
        height: auto;
    }

    .dropdown-menu {
        right: 0;
    }

    .app-search.search {
        width: 100%;
    }

    .MyScheduleMain {
        width: 48.8%;
        display: inline-block;
    }

    .display-flex {
        display: flex;
    }

    .Statuses .RecentRecord.RecentLeads.card-body,
    .Statuses .StatusListing.card-body {
        max-height: 608px;
        min-height: 608px;
    }

    .RecentRecord {
        height: calc(100vh - 44px);
    }

    .client-actions,
    #layout-wrapper .card .card-body table tr:last-child td {
        white-space: nowrap;
    }

    .CompanyDetail .page2 {
        width: 100%;
    }

    .page3 {
        width: 100%;
    }
}

@media screen and (max-width: 575px) and (min-width: 320px) {
    .top-navigation {
        background: #fff;
    }

    .topnav {
        height: auto;
    }

    .app-search.search {
        width: 100%;
    }

    .Statuses .VariousCalls.TotalRecord .card-body {
        min-height: auto;
        max-height: none;
    }

    .Campaigns,
    .Statuses .card-body,
    .Statuses .RecentRecord.RecentLeads.card-body,
    .Statuses .StatusListing.card-body,
    .RecentRecord.MySchedule ul,
    .RecentRecord.RecentLeads {
        min-height: auto;
    }

    .VariousCalls.card i.ion {
        font-size: 26px;
    }

    .VariousCalls.card .card .NumberFunc {
        font-size: 22px;
    }

    .ContactDescription h6.card-title {
        font-size: 12px;
    }

    .UserMessage p {
        font-size: 11px;
    }

    .RecentRecord ul li .StatusIcon {
        width: 15px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        flex: 30px 1;
    }

    .dropdown-menu {
        right: 0;
    }

    .client-search-bar {
        right: 0;
        min-width: 100%;
        width: 90%;
    }

    .MyScheduleMain {
        width: 100%;
    }

    .RecentRecord,
    .RecentRecord.MySchedule {
        height: auto;
    }

    .page-content.dialer .page3.deal-assign,
    .page-content.dialer .page3.invoice-details,
    .page-content.dialer .page3 {
        width: 100%;
    }

    .client-actions,
    #layout-wrapper .card .card-body table tr:last-child td {
        white-space: nowrap;
    }

    .CompanyDetail .page2 {
        width: 100%;
    }

    .page3 {
        width: 100%;
    }

}

.confirm-title-header {
    text-align: center;
    position: relative;
    height: 40px;
}

.confirm-title-header h3.title.btn-primary.btn.w-50 {
    position: absolute;
    top: -12px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.modal-backdrop {
    background-color: #ffffff;
}

/* .modal-backdrop.show{
    opacity: .9;
} */

.sidebar-close {
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 20px;
    cursor: pointer;
}

.Graph {
    position: relative;
}

.Graph .QuestionWizred {
    position: absolute;
    z-index: 2;
    width: 84%;
    right: 50px;
    height: 100%;
}

.Graph .QuestionWizred .OverLay {
    position: absolute;
    background: rgba(255, 255, 255, .8);
    left: -14%;
    right: -4%;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.cross-cls {
    position: absolute;
    right: 20px;
    font-size: 20px;
    top: 10px;
}

.modalQuestion {
    /* max-height: 460px;
    overflow: auto; */
    padding-bottom: 40px;
}

.modal-dialog.confirm .modal-content h5 {
    color: #000;
    font-weight: 600;
    font-size: 1.6em;
}

.modal-dialog.confirm .modal-content h5 b {
    font-weight: 800;
}

.modal-dialog.confirm .modal-content p {
    color: #000;
    opacity: 1;
    font-size: 1.1em;
}

.modal-dialog.confirm .modal-content h6 {
    font-size: 1.3em;
    color: #000;
}

.DashboardHeadingSection {
    max-width: 350px;
    margin: 0;
    text-align: center;
    background: #d5d2d2;
    /* color: #c5cad0; */
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: auto;
    text-transform: uppercase;
}

.DashboardHeadingSection h4.card-title {
    margin: 0;
}

.SummaryAssessment .survey.card.card-body table.table tr th:first-child {
    max-width: 0;
}

#lead-datatable-container table#lead-datatable th,
#lead-datatable-container table#lead-datatable td {
    text-align: center;
}

#lead-datatable-container table#lead-datatable td:last-child {
    border-right: 2px solid #ddd;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body {
    padding: 0;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body ul {
    width: 100%;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body ul li {
    display: flex;
    width: 100%;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .RecentRecord.card-body ul li .ContactDescription {
    display: inline-block;
     width: 100%; 
    /*min-width: 382px;*/
    margin-top: 0;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body ul li .StatusIcon {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-top: -20px;
}

.MessageTime span {
    margin: 0 0 0 10px;
}

.MessageTime {
    text-transform: uppercase;
    color: #222;
    margin-top: 5px;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body ul li .StatusIcon i.ion {
    font-size: 20px;
    line-height: 35px;
}

.Campaigns .ProgressDashboardCoaching {
    display: inline-block;
    width: 100%;
}






.calander-full-dashboard .fc-day-other.blockdays,
.calander-full-dashboard .fc-day-other.normaldays {
    background: #fff !important;
    color: #fff;
}

.calander-full-dashboard .fc-view-harness-passive .fc-dayGridMonth-view tbody tr td {
    border: 0;
}

.fc-dayGridMonth-view table.fc-scrollgrid table.fc-scrollgrid-sync-table tbody th,
.fc-dayGridMonth-view table.fc-scrollgrid table.fc-scrollgrid-sync-table tbody td {
    vertical-align: middle;
    border: 0;
    border-bottom: 2px solid #000;
}

.calander-full-dashboard table.fc-col-header thead {
    /* display: none; */
}

.calander-full-dashboard .fc .fc-daygrid-day-frame {
    min-height: auto;
    height: 45px;
}

.calander-full-dashboard .DayCal .fc .fc-daygrid-day-frame {
    height: auto;
    background: #fff;
}

.calander-full-dashboard .DayCal .fc-timegrid-event-harness {
    margin: 3px 0 0 0;
}

.fc .fc-scrollgrid table {
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-bottom-style: hidden;
}

.IconWithCount .dot {
    width: 15px;
    height: 15px;
    border-radius: 50px;
    margin: 0 0 5px 0px;
}

.calander-full-dashboard .card .card-body table td.fc-day-other,
.calander-full-dashboard .card .card-body table td.fc-day-other a {
    border-bottom: 0;
    opacity: 0;
    cursor: default;
    border-top: 0;
}

.calander-full-dashboard thead,
.calander-full-dashboard tfoot {
    /* display: none; */
}

.calander-full-dashboard .fc .fc-daygrid-day-top a {
    color: #000;
}

.calander-full-dashboard .fc-timegrid-event-harness>.fc-timegrid-event {
    position: static;
    top: auto;
    bottom: 0;
    left: 0;
    padding: 0 !important;
    right: 0;
    margin: 3px 0 0 0 !important;
}

.calander-full-dashboard .MonthCal .fc-event,
.calander-full-dashboard .MonthCal .fc-event-dot {
    background: transparent;
}

.calander-full-dashboard .MonthCal .fc-daygrid-day-events {
    position: absolute;
    top: auto;
    right: 5px;
    min-height: 0;
    margin: 0;
    bottom: 3px;
    text-align: right;
    width: 14px;
    line-height: 0;
    height: 14px;
    left: auto;
}

.calander-full-dashboard .card-body {
    background: #fff;
    border: 0;
}

.calander-full-dashboard .table-bordered {
    border: 0;
}

.NewCoachDashboard .calander-full-dashboard .DayCal {
    /* height: 295px; */
    overflow: auto;
    position: relative;
    z-index: 2;
    background: #fff;
}

.calander-full-dashboard .DayCal table tbody tr td {
    border: 0;
    border-bottom: 2px solid #000;
    background: #fff;
}

.calander-full-dashboard span.event-label-month i.fas.fa-circle {
    position: absolute;
    left: 0;
    top: 0;
}

.calander-full-dashboard .MonthCal .fc .fc-toolbar.fc-header-toolbar {
    display: none;
}

.calander-full-dashboard input.form-control {
    width: 40%;
    float: right;
    height: 30px;
    margin: 5px 22px 0 0px;
    background: #f1f1f1;
}

.calander-full-dashboard .MonthCal {
    position: relative;
    z-index: 1;
}

.calander-full-dashboard td.fc-timegrid-divider.table-active {
    display: none;
}

.calander-full-dashboard .DayCal .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    text-align: left;
    justify-content: flex-start;
    margin-top: 0px;
}

.calander-full-dashboard .card {
    margin: 0;
}

.selectboxtop {
    width: 100%;
    display: inline-block;
    position: relative;
    height: 40px;
    z-index: 1;
    margin-top: 0;
}


.selectboxtop .css-2b097c-container {
    position: absolute;
    right: 26px;
    width: 150px;
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 2px), calc(100% - 8px) calc(0.5em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    z-index: 1;
    border: 0;
    min-height: 25px;
    border-radius: 5px;
    height: 25px;
}

.selectboxtop .css-g1d714-ValueContainer {
    z-index: 1;
    font-size: 13px;
    position: absolute;
    width: 100%;
    top: 0;
    height: 24px;
}

.selectboxtop .css-tlfecz-indicatorContainer {
    color: transparent;
    padding: 0;
}

.VariousCalls.TotalRecord.MyScheduleMain h5.card-title {
    font-size: 14px;
    width: 35%;
    float: right;
}

.NewCoachDashboard .calander-full-dashboard input.form-control,
.StatusHeading input.form-control {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 2px), calc(100% - 8px) calc(0.5em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    width: 145px;
    background-color: #f1f1f1;
    height: 25px;
    position: relative;
    z-index: 1;
    border: 0;
    left: 0px;
    float: left;
    min-width: 140px;
}

.StatusHeading input.form-control {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
}
.StatusHeading .card-title {
    margin: 0 0 7px 15px;
}
.StatusHeading .card-title button.btn.btn-link.border-0.float-end.pb-0 {
    padding: 0;
    line-height: 30px;
}

.selectboxtop .select2-selection__indicators.css-1hb7zxy-IndicatorsContainer {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 13px) calc(0.5em + 3px), calc(100% - 9px) calc(0.5em + 3px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    background-color: #d9d9d9;
    min-height: 23px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.selectboxtop .css-yk16xz-control {
    height: 25px;
    min-height: 25px;
    border: 0;
    border-radius: 5px;
    overflow: hidden;
}

.selectboxtop .css-1pahdxg-control {
    background: transparent;
    border-color: #d9d9d9;
    min-height: 25px;
    overflow: hidden;
    height: 25px;
    border: 0;
    box-shadow: none;
}

.selectboxtop .css-1pahdxg-control:hover {
    border-color: transparent;
}

.selectboxtop .css-1okebmr-indicatorSeparator {
    display: none;
}

.selectboxtop .css-b8ldur-Input {
    margin: 0;
    padding: 0;
}

.selectboxtop .css-tj5bde-Svg {
    color: transparent;
}

input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
    position: absolute;
    z-index: 0;
    right: 0;
    height: 100%;
    width: 145px;
    z-index: 9;
}

.selectboxtop .css-1uccc91-singleValue {
    width: 75%;
}

.SummaryAssessment .survey.card.card-body table.table tr th.implementation-th {
    min-width: 200px;
    width: 200px;
}

.finincial-break-down--edit-btn {
    position: absolute;
    top: -38px;
    right: 38px;
}

.ProfitSide {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.GulakArea {
    /* width: auto; */
    display: flex;
    position: relative;
    /* height: 135px; */
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    /* min-height: 175px; */
    /* height: 100%; */
    width: 100%;
    max-width: 350px;
}

.GulakArea img {
    width: auto;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.GreenStatus {
    position: absolute;
    width: 98%;
    height: 25%;
    bottom: 4px;
    z-index: 0;
}

.PercentNumbers {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}

.PercentNumbers h2 {
    color: #000;
}

/* .YellowStatusBox {
    position: absolute;
    left: 0;
    right: 0;
    width: 59px;
    margin: auto;
    border: 0;
    overflow: hidden;
    height: 55px;
    bottom: 80px;
} */
.YellowStatusBox {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    overflow: hidden;
    min-height: 36%;
    bottom: 60.2%;
    max-width: 38%;
    background: #fff;
    z-index: 1;
    border-radius: 0 0 30px 30px;
}

.GreenStatusBox {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    overflow: hidden;
    min-height: 72%;
    bottom: 0;
    z-index: 0;
    border-radius: 0 0 30px 30px;
}

.YellowStatus {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    border-radius: 0 0 18px 18px;
}

.ClientProfileDetails .Breakdown .ROiPercents .SectionHeader.RoiPage {
    padding: 2px;
    border-width: 2px;
}

.ClientProfileDetails .Breakdown .ROiPercents {
    width: 100%;
    display: inline-block;
}

.NewLeadDialer.ClientProfileDetails .Breakdown .ROiPercents .revenue p {
    font-size: 10px;
    text-transform: uppercase;
}

.ClientProfileDetails .ROiPercents .SectionHeader.RoiPage .Percents h4.GrayBG {
    height: 20px;
}

.ClientProfileDetails .ROiPercents .SectionHeader.RoiPage .Percents h4 {
    height: 20px;
    font-size: 12px;
}

.NewLeadDialer .SectionHeader.RoiPage .Percents h4.GrayBG span.pull-left {
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
}

.ClientProfileDetails .ROiPercents .SectionHeader.RoiPage .Percents h4 {
    margin: 0;
    height: 20px;
    padding: 0 5px 0 5px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
}

.ClientProfileDetails .TotalPercent {
    height: auto;
}

.NewLeadDialer .clientProfileHeading {
    position: relative;
    top: -15px;
    min-width: 80%;
    margin: 0 auto;
    padding: 5px;
    max-width: 80%;
}

.NewLeadDialer .boxescard.card .card-body {
    min-height: 260px;
    max-height: 260px;
}

.NewLeadDialer .clientProfileHeading h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
}

.GoalSwiltch.ProfileToggle {
    float: right;
}

.GoalSwiltch.ProfileToggle .form-check.form-switch {
    float: left;
    width: auto;
    margin: 0 0 0 20px;
}

/* .ClientProfileDetails .Breakdown .ROiPercents .revenue {
    font-size: 10px;
} */
.NewLeadDialer .clientProfileHeading h5 {
    font-size: 14px;
    text-transform: uppercase;
}

.NewLeadDialer .boxescard.card .card-body .card.card-body.Breakdown.PathwayProgression {
    border: 0;
    min-height: 215px;
    max-height: 215px;
    padding: 0;
    margin: 0;
    box-shadow: none;
    overflow: auto;
}

.clientProfileHeading.ToolKit {
    max-width: 310px;
}

.ProfitBalance {
    width: 100%;
    display: inline-block;
}

.CurIcon {
    width: 25px;
    height: 25px;
    background: #C58524;
    color: #fff;
    text-align: center;
    font-size: 15px;
    border-radius: 50%;
    line-height: 25px;
    float: left;
    margin: 0 10px 0 -20px;
}

.ProfitBalance h5 {
    width: 88%;
    margin: 0px 0 0 0;
    float: left;
    font-size: 15px;
}

.ProfileBreakDown {
    min-height: 275px;
    max-height: 275px;
    overflow: auto;
    width: 100%;
    display: inline-block;
    padding-right: 5px;
}
.ProfilePipelineStats {
    min-height: 245px;
    max-height: 245px;
    overflow: auto;
    width: 100%;
    display: inline-block;
    padding-right: 5px;
}
.CompanyDetail.ClientProfileDetails .card.card-body {
    min-height: 325px;
    padding-bottom: 15px;
    display: inline-block;
    width: 100%;
    max-height: 325px;
}

.profileBadges ul.summary-report li {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    min-height: 45px;
    height: 45px;
    margin: 0 5px 0 0;
}

.profileBadges .BottomShade {
    bottom: -6px;
}

.profileBadges .BottomShade img {
    width: 40px;
}

.profileBadges ul.summary-report li p {
    font-size: 10px;
    min-height: 28px;
    line-height: normal;
}

.NewLeadDialer .SectionHeader.RoiPage .Percents h4.FullBG {
    justify-content: center;
    text-transform: uppercase;
    font-weight: normal;
}

.profileBadges ul.summary-report.h-100px {
    height: 60px;
}

.ClientProfileDetails .SectionHeader.RoiPage .TotalPercent {
    z-index: 0;
    right: 0;
}

.ClientProfileDetails span.percentageSign {
    font-weight: 500;
    color: #021732;
}

.TasksAsign {
    list-style: none;
    padding: 0;
}

.TasksAsign li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
}

.TasksAsign .StatusIcon {
    float: left;
    font-size: 22px;
    color: #7b0404;
    margin: 0 5px 0 0px;
    line-height: normal;
}

.TasksAsign .TaskNumber.complete {
    background: rgb(244, 188, 51);
}

.TasksAsign .TaskNumber.assigned {
    background: #C58524;
}

.TasksAsign .TaskNumber.in-review {
    background: #C58524;
}

.TasksAsign .TaskNumber {
    background: #021732;
    min-width: 56px;
    float: left;
    text-align: center;
    color: #fff;
    height: 80px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: 0 10px 0 0px;
}

.TaskDesription {
    padding-bottom: 8px;
    border-bottom: 2px solid #454444;
    width: 100%;
}

.TaskDesription p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ProfileClientActivity .page3.ProfileClient {
    height: calc(100vh - 105px);
    bottom: 22px;
    top: auto;
    overflow: unset;
    background: #f1f1f1;
    z-index: 7;
}

.CloseBtn {
    position: absolute;
    top: 5px;
    right: 10px;
    background: #d9d9d9;
    height: 30px;
    width: 30px;
    padding: 0;
    font-size: 20px;
    font-weight: normal;
}

.ActivityPanel .DashboardHeadingSection.WelcomeTraining {
    position: relative;
}

.ActivitiesList {
    height: calc(100vh - 260px);
    overflow: auto;
}

.MonthlyDropDown {
    width: 100%;
    text-align: right;
    display: inline-block;
}

.MonthlyDropDown select.form-control {
    width: 200px;
    float: right;
}

.MonthlyDropDown select.form-control {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 7px), calc(100% - 8px) calc(0.5em + 7px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    /* background-color: #fff; */
    background-color: #f1f1f1;
    height: 30px;
    position: relative;
    z-index: 1;
    border: 0;
    right: 0;
}

.NewClientDialer .InboxBusiness ul li.activity-note-in .desp-box {
    max-width: 215px;
    min-width: 215px;
}

.ProfileClientActivity .page3.ProfileClient .MonthlyDropDown select.form-control {
    background-color: #f1f1f1;

}

.VariousCalls.boxescard .MonthlyDropDown select.form-control {
    background-color: #f1f1f1;
}


.task label {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #00002a, #00002a);
    background-position: calc(100% - 12px) calc(0.5em + 7px), calc(100% - 8px) calc(0.5em + 7px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    height: 30px;
    position: relative;
    z-index: 1;
    border: 0;
    right: 0;
    width: 120px;
    flex-direction: row;
    padding: 6px 0 0 8px;
    border-radius: 5px;
}

.task-client-action button.btn.btn-success {
    opacity: 1;
    background-color: #C58524;
    border-color: #C58524;
}

.RecentRecord.ProfileMessages ul li {
    column-gap: 0;
    margin: 0;
    width: 100%;
}

.CompanyDetail.ClientProfileDetails .ActivityPanel.card.card-body {
    height: calc(100vh - 205px);
    min-height: auto;
    max-height: unset;
}

.RecentRecord.ProfileMessages ul li {
    display: inline-block;
}

.RecentRecord.ProfileMessages ul li .StatusIcon {
    float: left;
    flex: auto;
}

.RecentRecord.ProfileMessages ul li .ContactDescription {
    float: none;
    width: 83%;
    /* margin: 0 auto; */
    display: inline-block;
}

.RecentRecord.ProfileMessages ul {
    height: calc(100vh - 537px);
    max-height: unset;
}

.RecentRecord.ProfileMessages ul li .StatusIcon.Message {
    background: #C58524;
}

.RecentRecord.ProfileMessages ul li .StatusIcon.Message i.mdi.mdi-comment {
    transform: scale(-1, 1);
}

.RecentRecord.ProfileMessages .ContactDescription h6 {
    float: left;
}

.NameUser {
    width: 100%;
    display: inline-block;
}

.RecentRecord.ProfileMessages .ContactDescription h6 {
    float: left;
}

.MessageDate {
    margin: 0 0 0 10px;
    font-size: 16px;
    float: left;
    line-height: normal;
    font-weight: 600;
}


.ActivityPanel .activity-bubble.call:before {
    background-image: url(../images/profile-status/call.png);
    background-color: #34c934;
}

.ActivityPanel .activity-bubble.note:before {
    background-image: url(../images/profile-status/note.png);
    background-color: #40b7ad;

}

.ActivityPanel .activity-bubble.task:before {
    background-image: url(../images/profile-status/task.png);
    background-color: #00a03d;
}

.ActivityPanel .activity-bubble.sms:before,
.activity-bubble.mms:before {
    background-image: url(../images/profile-status/sms.png);
    background-color: #2196f3;

}

.ActivityPanel .activity-bubble.email:before {
    background-image: url(../images/profile-status/email.png);
    background-color: #77a05e;

}

.ActivityPanel .activity-bubble.other:before {
    background-image: url(../images/profile-status/other.png);
    background-color: #ff6900;
}

.ActivityPanel .activity-bubble.contract:before {
    background-image: url(../images/profile-status/contract.png);
    background-color: #941717;
}

.ActivityPanel .activity-bubble.rvm:before {
    background-image: url(../images/profile-status/rvm.png);
    background-color: #f05282;
    background-size: 20px;
}

.ActivityPanel .activity-bubble.voice:before {
    background-image: url(../images/profile-status/voicemail.png);
    background-color: #57487e;
    background-size: 11px;
}

.ActivityPanel .activity-bubble.deal:before {
    background-image: url(../images/profile-status/deal.png);
    background-color: #ff5b62;
    background-size: 25px;
}



.CompanyDetail .ActivityPanel .activity-content {
    min-width: 100%;
    color: #3d4f5f;
    padding: 10px 5px;
    width: 100%;
    background-color: #f1f1f1;
    /* box-shadow: 0px 0 5px 2px #ddd; */
    border-radius: 3px;
    border: 0;
    text-align: left;
    position: relative;
    z-index: 2;
}

.CompanyDetail .ActivityPanel .activity-bubble:after {
    width: 60px;
    height: 1px;
    content: '';
    position: absolute;
    background: #454444;
    left: -63px;
    z-index: 1;
}

.CompanyDetail .ActivityPanel .activity-body {
    width: 100%;
    margin-left: 85px;
    position: relative;
    margin-right: 5px;
}

.CompanyDetail .ActivityPanel .activity-body .activity-bubble:before {
    left: -95px;
}

.survey .MonthlyDropDown {
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;
}

.survey section.breakthrough.conversation {
    justify-content: space-between;
}

.survey section.breakthrough.conversation .MonthlyDropDown select.form-control {
    width: 128px;
}

.survey .card.card-body.Breakdown.RoiBreakDown {
    min-height: auto;
}

.survey .card.card-body.Breakdown.RoiBreakDown .LeadsSales .toogle-sales {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 30px;
}

.survey .card.card-body.Breakdown.RoiBreakDown .LeadsSales {
    top: -19px;
    bottom: 0;
    height: calc(100% + 40px);
    position: static;
    margin: -20px 0px 0 20px;
}

.survey .card.card-body.Breakdown.RoiBreakDown .card.card-body {
    min-height: 369px;
}

.JourneyVideo video {
    width: 100%;
    height: auto;
}

.meeting-schdule .accordion-head {
    display: grid;
    grid-template-columns: 75% auto;
    background: #f1f1f1;
    margin-bottom: 5px;
}

.Statuses.clientProfileDetail ul li {
    width: 100%;
}

.Statuses.clientProfileDetail .StatusIcon.Call {
    width: 35px;
}

.NewLeadDialer .ClientProfileDetails .card.boxescard {
    min-height: calc(100vh - 470px);
    display: inline-block;
    width: 100%;
}

.ClientProfileDetails .card.boxescard .card.card-body {
    min-height: 285px;
    max-height: 260px;
}

.FixedSidebar {
    width: 14.8vw;
    position: fixed;
    right: 0.9vw;
}

.CompanyDetail.ClientDialer.ClientProfileDetails .NoShadow.custom-scroll.LeftSideUser.FixedLeftSidebar {
    width: 23.2vw;
    position: fixed;
    top: 83px;
    left: 1vw;
    z-index: 9;
}

.ClientProfileDetails .card.boxescard.calanderbox {
    min-height: auto;
}

.CompanyDetail.ClientDialer.ClientProfileDetails .NoShadow.custom-scroll.LeftSideUser.FixedLeftSidebar .AboutCard {
    background: #fff;
    border: 0;
    height: calc(100vh - 382px);
}

.page-content.dialer .NoShadow.card.FixedSidebar {
    border: 0;
}

.CompanyDetail.ClientDialer.ClientProfileDetails .RecentRecord.ProfileMessages {
    height: calc(100vh - 436px);
}

.CoachDailer.card .accordion-item,
.ClientDialer .accordion-item {
    border-left: 0;
    border-right: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    background: #f1f1f1;
    border-radius: 0px;
    margin-bottom: 5px;
    border: 0;
}

.Counters.CustomerBreakDown .Count-LastYear {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.LeadsSales .Counters.CustomerBreakDown .Count-LastYear span.CountDesc {
    float: none;
    margin: 0 10px 0 0px;
}

.Counters.CustomerBreakDown .Count-LastYear span.CountNumber {
    min-width: 30%;
    background: #fff;
    float: none;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
    width: auto;
    padding: 0 10px;
}

.form-check.form-switch.ConversionSwith label {
    color: #000;
}

.form-check.form-switch.ConversionSwith input.form-check-input {
    float: right;
    margin: 3px 10px 0 5px;
}

.survey .card.card-body.Breakdown.RoiBreakDown .LeadsSales .toogle-sales.ConversionSwith {
    text-align: right;
}

.details.ProflieActions button.btn.btn-link img {
    width: 20px;
}

.NoShadow.custom-scroll.FixedSidebar.CoachDailer.card {
    border: 0;
}

.page-content.dialer .NoShadow.card .details.meeting-schdule .accordion-item {
    border: 0;
}

.form-check-input:checked {
    background-color: #626262;
    border-color: #626262;
}

.ClientProfileDetails .card.boxescard.calanderbox input.form-control {
    right: 0;
    margin-right: 10px;
    margin-top: 0;
}

.NewLeadDialer.ClientProfileDetails .card.boxescard.calanderbox .calander-full-dashboard .DayCal {
    margin-top: -30px;
    position: relative;
    z-index: 1;
    max-height: 245px;
    overflow: auto;
}
.NewLeadDialer.ClientProfileDetails .card.boxescard.calanderbox .calander-full-dashboard .DayCal ul.CalendarTask {
    height: calc(100vh - 450px);
    display: inline-block;
    width: 100%;
    /* overflow: auto; */
    padding-top: 5px;
}

.MonthlyDropDown.p-tracker {
    width: auto;
}

.NoShadow.custom-scroll.FixedSidebar.CoachDailer.card {
    border: 0;
    height: calc(100vh - 113px);
}

.Breakdown.PathwayProgression .Percents h4 {
    font-size: 16px;
    font-weight: normal;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.SummaryAssessment .Breakdown.PathwayProgression .ProfileBreakDown .SectionHeader.RoiPage .Percents h4 span {
    margin: 0;
}

.SummaryAssessment section.breakthrough.clientProfileHeading {
    min-width: 50%;
}

.modalQuestion button.BtnCenter {
    position: relative;
    top: -30px;
    margin: 0 auto;
    display: block;
}

.modalQuestion button.BtnCenterClose {
    position: absolute;
    right: 12px;
    top: 5px;
    font-size: 20px;
}

.ProfileBreakDown.PTracker.ClientProfile {
    height: calc(100vh - 140px);
    min-height: auto;
    max-height: unset;
}


.task button.btn.btn-primary {
    opacity: 1;
}

.ProfileBreakDown.PTracker.ClientProfile .TasksAsign {
    background: #fff;
}

.page3.ProfileClient.slide-in.white-bg.tab-slider.custom-scroll.contract-details {
    background: #fff;
}

.ProfileBreakDown.PTracker.ClientProfile .TasksAsign li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
}

.video-button button.btn.btn-primary.mr-5 {
    position: absolute;
    right: 15px;
    z-index: 9;
    top: 20px;
}

.TasksAsign.tracker-list li {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.client-quick-dialer .dropdown-menu.show {
    right: 0 !important;
    left: auto;
}

.imp-time.rounded-3 {
    border-radius: 1rem !important;
}






/* =========================== Video Chat============================ */
.host-video {
    height: calc(100vh - 122px);
}

.page-content.chat.group-chat {
    padding: calc(24px + 15px) calc(0px / 2) 15px calc(0px / 2);
    height: calc(100vh - 20px);
}

.host-video .chat,
.remoote-video .chat {
    background: #fff;
    height: calc(100vh - 87px);
    display: inline-block;
    width: 100%;
}

.host-video .message {
    height: calc(100vh - 119px);
    overflow: auto;
}

.remoote-video .message {
    height: calc(100vh - 135px);
    overflow: auto;

}

.shareable-link {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}

.customVideoRoom .host video {
    width: 100%;
    height: calc(100vh - 86px);
    object-fit: cover;
}

.host-video .video-control {
    bottom: 40px;


}

.JoinVideoChat {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
}

.JoinVideoChat form {
    min-width: 300px;
}

/* .customVideoRoom .remote {
    width: 100px;
    height: 100px;
    position: relative;
    object-fit: cover;
} */
.customVideoRoom .remote-video video {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.remote-participants-standalone .participant.remote-video .RemoteUser {
    position: absolute;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    background: rgba(0, 0, 0, .2);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remote-participants-standalone .participant.remote-video {
    position: relative;
    display: inline-block;
    margin: -130px 0 0px 11px;
    border: 3px solid #fff;
}

.remote-participants-standalone .participant.remote-video .remote {
    width: 100px;
    height: 100px;
    display: inline-block;
    position: relative;
}

.remote-participants-standalone .participant.remote-video video {
    width: 100%;
}

.remote-participants-standalone .participant .host {
    position: absolute;
    width: 100%;
    top: 0;
}

.local-participant-standalone.host-remote {
    width: 300px;
    position: absolute;
    z-index: 9;
    right: 20px;
    top: 20px;
    height: 300px;
}

.local-participant-standalone .participant.remote-video {
    width: 300px;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
}

.video-control {
    position: absolute;
    bottom: 15px;
    width: 190px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    z-index: 999999;
}

.local-participant-standalone .participant.remote-video .remote .RemoteUser {
    position: absolute;
    z-index: 11;
    width: 100%;
    top: 0;
    background: rgba(0, 0, 0, .4);
    text-align: center;
    color: #fff;
}

.local-participant-standalone .video-control {
    position: absolute;
    bottom: 90px;
    width: 190px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    z-index: 999999;
}

.local-participant-standalone.host-local .video-control {
    bottom: 150px;
}

.remote-participants-standalone.host-remote {
    /* position: absolute; */
    width: 100%;
    bottom: 20px;
    left: 20px;
}

.remote-participants-standalone.remote-remote {
    position: absolute;
    width: 100%;
}

.message-list li.other,
.message-list li.me {
    display: flex;
    flex-shrink: 0;
    line-height: normal;
    height: auto;
}

.message-list li.me {
    flex-direction: row-reverse;
    margin-left: auto;
    height: auto;
}

.message-list li:hover {
    background: transparent;
}

/* ================================================================ */
.nav-bg #ii0u {
    color: #fff;
}

.left-shown .dropdown-menu.show {
    right: 0 !important;
}

i.fas.code-red {
    color: red;
    background-color: transparent !important;
}

i.fas.code-green {
    color: green;
    background-color: transparent !important;
}

.calander-full-dashboard .fc .fc-scrollgrid-section-sticky>* {
    position: static;
}

.code-red {
    background-color: #960303 !important;
    color: #fff;
}

.code-blue {
    background-color: var(--bd-color, #3a3b3d) !important;
    color: #fff;
}

.code-green {
    background-color: #C58524 !important;
    color: #fff;
}

.code-yellow {
    background-color: #f4bc33 !important;
    color: #fff;
}

.code-dark {
    background-color: #454444 !important;
    color: #fff;
}

.code-black {
    background-color: #000 !important;
    color: #fff;
}

.DotTask {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    flex-shrink: 0;
    margin: 0 0 0 5px;
}

.Statuses .RecentRecord.MyTasks.NewTasks ul li {
    text-align: left;
    justify-content: flex-start;
    border: 0;
    margin: 0;
}

.no-record .ContactDescription {
    width: 100%;
}

.NewTasks .TaskDescp {
    margin: 0 0 0 10px;
    background: #fff;
    width: 90%;
    padding: 0;
    border-radius: 5px;
}

.NewTasks .TaskDescp p {
    color: #000;
}

.NewTasks .research-date p {
    text-transform: uppercase;
}

.Statuses .RecentRecord.MyTasks.NewTasks ul li hr {
    background-color: #000;
}

.Statuses .RecentRecord.MyTasks.NewTasks ul {
    max-height: 280px;
    margin-top: 30px;
}

.text-right {
    text-align: right;
}

.NameUser h6.card-title {
    font-size: 14px;
    color: #222;
    text-transform: uppercase;
}

.UserMessage p {
    font-size: 13px;
    color: #222;
}

.VariousCalls.BusinessInbox .RecentRecord.card-body ul li i.fas.fa-reply {
    background: #C58524;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    line-height: 30px;
    margin: -20px 0 0 10px;
}

.calander-full-dashboard .fc .fc-scrollgrid table thead th.fc-col-header-cell {
    color: #000;
}

.VariousCalls.BusinessInbox.card {
    margin: 0;
}

.NewCoachDashboard .card {
    margin: 0;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .card-body .RecentRecord.card-body {
    height: auto;
    min-height: auto;
}

.NewCoachDashboard ul.CalendarTask {
    padding: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
    max-height: 265px;
    min-height: 265px;
    overflow: auto;
    margin: 0;
}

.NewCoachDashboard ul.CalendarTask li .TaskDescp {
    /* margin: 0 0 0 10px; */
    /* background: #f1f1f1; */
    margin: 0;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
}




.NewCoachDashboard ul.CalendarTask li {
    text-align: left;
    justify-content: flex-start;
    border: 0;
    margin: 0;
    align-items: center;
    width: 100%;
    display: flex;
}

.no-record .ContactDescription {
    width: 100%;
}

.NewCoachDashboard ul.CalendarTask li .TaskDescp p {
    color: #000;
}

.NewCoachDashboard ul.CalendarTask .research-date p {
    text-transform: uppercase;
}

.NewCoachDashboard ul.CalendarTask li hr {
    background-color: #000;
}

.NewCoachDashboard ul.CalendarTask ul {
    max-height: 280px;
    margin-top: 30px;
}

.NewCoachDashboard ul.CalendarTask .DotTask {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    float: none;
}

ul.todo-list li button.btn {
    height: 35px;
    width: 35px;
    float: left;
    margin: 0;
}

ul.list-group.todo-list {
    list-style: none;
    padding: 0;
    overflow: auto;
    min-height: 180px;
    max-height: 180px;
}

.todotask .list-todo {
    width: 100%;
}

.todotask .list-todo p.notes {
    width: 79%;
    display: inline-block;
}

.todotask .list-todo p.notes {
    width: 78%;
    display: inline-block;
    background: #f1f1f1;
    padding: 5px;
    border-radius: 5px;
    margin: 0 0 0 5px;
}

.boder-green {
    border-color: #C58524;
    color: #C58524;
}

.btn.btn-outline-success.boder-green.rounded-100 {
    padding: 0;
    text-align: center;
    font-size: 20px;
}

button.btn.btn-link.Remove-Action {
    background: #f1f1f1 !important;
    border-radius: 50px;
    color: #860606;
}

.CalendarSelectDate {
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.NewCoachDashboard .VariousCalls.TotalRecord.MyScheduleMain .card-body .card-body {
    min-height: auto;
    max-height: unset;
    box-shadow: none;
}

.NewLeadDialer .PointsContent {
    min-height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.NewLeadDialer .PointsCount {
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
}

.NewLeadDialer .PointsCount h4 {
    margin: 0;
}

.NewLeadDialer .TotalPoints {
    height: 95px;
    margin-top: -6px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-size: 40px;
}

.NewLeadDialer .PointsCount i {
    font-size: 18px;
}

.NewLeadDialer .boxescard.card .card-body .calander-full-dashboard .card-body {
    border: 0;
    min-height: auto;
    box-shadow: none;
}

.NewLeadDialer .boxescard.calanderbox.card .card-body {
    min-height: 600px;
}

.NewLeadDialer .CalendarSelectDate {
    text-transform: capitalize;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.NewLeadDialer ul.CalendarTask {
    list-style: none;
    padding: 0;
    margin: 0;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .card,
.NewCoachDashboard .VariousCalls.BusinessInbox .card .RecentRecord.card-body {
    box-shadow: none;
    border-radius: 0;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .border-three .card {
    box-shadow: 0px 0px 5px 0px #999;
}

.NewCoachDashboard .VariousCalls.BusinessInbox .RecentRecord.card-body ul li {
    display: flex;
    width: 98%;
    margin-bottom: 5px;
}

.NewLeadDialer ul.CalendarTask li {
    align-items: center;
    display: flex;
    box-shadow: 0px 0px 5px 0px #999;
    width: 97%;
    margin: 0 auto 15px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.NewLeadDialer .TaskDescp {
    background: #fff;
    width: 100%;
    margin: 0 0 0 8px;
    padding: 5px 0;
    border-radius: 5px;
}

.NewLeadDialer .DotTask {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin: 0;
    flex-shrink: 0;
}

.NewLeadDialer .IconWithCount span {
    width: 100%;
    display: inline-block;
    font-size: 12px;
    color: #000;
}

.NewLeadDialer .VariousCalls.TotalRecord .card .NumberFunc {
    color: #000;
    font-size: 28px;
}

.NewLeadDialer .calander-full-dashboard input.form-control,
.NewLeadDialer .StatusHeading input.form-control {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 2px), calc(100% - 8px) calc(0.5em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    width: 145px;
    background-color: #f1f1f1;
    height: 25px;
    position: relative;
    z-index: 1;
    border: 0;
    left: 0px;
    float: left;
}

.NewLeadDialer .selectboxtop.PlusSign {
    width: 100%;
    display: inline-block;
    height: auto;
    margin: 0;
}

.NewLeadDialer .selectboxtop.PlusSign.CalanderPanel {
    float: right;
    width: auto;
    margin: 0;
    padding: 0;
    height: 45px;
}

.NewLeadDialer .calander-full-dashboard .MonthCal {
    margin: 0 !important;
}

.NewLeadDialer .selectboxtop.PlusSign button.btn.btn-primary.code-green {
    height: 25px;
    padding: 0 15px;
}

.NewLeadDialer .VariousCalls.TotalRecord.MyScheduleMain.TaskManager .card-body {
    min-height: 600px;
    margin: 0;
}

.MyScheduleMain.card .RecentRecord.MyTasks.NewTasks.card-body {
    min-height: auto;
}

.NewLeadDialer .boxescard.card .card-body.InboxBusiness ul {
    padding: 0;
    list-style: none;
    min-height: 295px;
    max-height: 295px;
    margin: 0;
    overflow: auto;
    padding-bottom: 10px;
}

.NewLeadDialer .ContactDescription {
    background: #fff;
    border-radius: 5px;
    width: 88%;
    margin: 0 auto;
}

.NewLeadDialer .boxescard.card .InboxBusiness.card-body {
    min-height: 600px;
    max-height: 600px;
}

.NewLeadDialer .boxescard.card .card-body button.btn.code-green {
    height: 25px;
    padding: 0 10px;
    line-height: normal;
    margin: 0 0 0 0;
}

.NewLeadDialer .VariousCalls.card .MyScheduleMain.card {
    background: #fff;
    margin-top: 5px;
}

.NewLeadDialer .RecentRecord ul {
    max-height: 385px;
}

.NewLeadDialer .InboxBusiness ul li {
    justify-content: space-between;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-in {
    display: flex;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out , .NewLeadDialer .InboxBusiness ul li.activity-note-system_out {
    padding-left: 20px;
    width: 98%;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .StatusIcon,
.NewLeadDialer .InboxBusiness ul li.activity-note-system_out .StatusIcon,
.NewLeadDialer .InboxBusiness ul li.activity-note-in .StatusIcon {
    width: 30px;
    height: 30px;
    background: #C58524;
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 0 5px 0 0;
    float: left;
    line-height: 30px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .ContactDescription,
.NewLeadDialer .InboxBusiness ul li.activity-note-in .ContactDescription {
    background: #f1f1f1;
    border-radius: 15px;
    width: 80%;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .StatusIcon i,
.NewLeadDialer .InboxBusiness ul li.activity-note-in .StatusIcon i {
    font-size: 15px;
}

.NewLeadDialer ul li.activity-note-out .NameUser h6.card-title {
    color: #fff;
    font-size: 11px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .ContactDescription {
    background: #454444;
    width: auto;
    float: left;
    min-width: 82%;
    max-width: 82%;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-in .ContactDescription {
    width: 100%;
    margin: 0;
}

#layout-wrapper .calander-full-dashboard .MonthCal table thead th {
    text-align: right;
}

.NewLeadDialer .MessageDate {
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
    margin: 5px 0 0 10px;
    display: inline-block;
}

.NewLeadDialer .MessageDate span {
    margin: 0 0px 0 10px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .UserMessage p {
    font-size: 13px;
    color: #fff;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .desp-box {
    float: left;
    width: 87%;
    padding: 0 10px 0 0px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-in .StatusIcon {
    margin: 0;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-in .desp-box {
    float: left;
    width: 260px;
    margin: 0;
    margin: 0 0px 0 5px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .reply-icon {
    float: left;
    width: 25px;
    height: 25px;
    background: #C58524;
    color: #fff;
    border-radius: 50px;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    margin: 0 0 0 5px;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-out .StatusIcon {
    margin: -20px 0 0 0;
}

.NewLeadDialer .InboxBusiness ul li.activity-note-in .reply-icon {
    display: none;
}

.ClientProfileDetails .DashboardHeadingSection {
    max-width: 250px;
}

.ClientProfileDetails .clientProfileHeading {
    max-width: 250px;
    margin: 0 auto;
    position: relative;
    top: -17px;
    text-transform: uppercase;
}

.ClientProfileDetails .Breakdown .ROiPercents .revenue {
    text-transform: uppercase;
}

.ClientProfileDetails .clientProfileHeading h5 {
    text-transform: uppercase;
}

.NoShadow.card.ProfileClientActivity .card-body {
    min-height: 608px;
}

/* .ClientProfileDetails .VariousCalls.TotalRecord.MyScheduleMain.TaskManager .card-body {
    min-height: auto;
} */
.ClientProfileDetails .RecentRecord ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 330px;
    overflow: auto;
}

.ClientProfileDetails .VariousCalls.TotalRecord.MyScheduleMain.TaskManager.card {
    margin: 0;
}

.ProductivityIcons {
    min-height: 555px;
    display: flex;
    align-items: center;
}

/*.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .ProfileBreakDown {
    min-height: calc(100vh - 293px);
    max-height: calc(100vh - 293px);
}*/

/*.NoShadow.custom-scroll.Activity.UserActivityDetails.ProfileClientActivity.Events .card-body {
    min-height: 510px;
}*/

.my-coaches .CoachRevnewCount {
    background: #454444;
    border: 5px solid #d9d9d9;
    border-radius: 8px;
    min-height: 168px;
}

.my-coaches .RevenueCoach {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.my-coaches .CoachRevCount {
    text-align: center;
    color: #fff;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.my-coaches .CoachesLists {
    margin: 0;
    padding: 0;
}

.my-coaches .LastLogin {
    text-transform: uppercase;
    color: #000;
}

.my-coaches .MyCoachesList .DashboardHeadingSection {
    max-width: 425px;
    padding: 10px 0;
    top: -24px;
}

.my-coaches .StatusDot {
    width: 13px;
    height: 13px;
    border-radius: 50px;
    margin: 0 10px 0 0px;
    display: inline-block;
    position: relative;
    top: 2px;
}

.my-coaches .CoachStatus {
    text-transform: uppercase;
}

.my-coaches .CoachStatus .Offline span {
    font-weight: 500;
    color: #960303;
}

.my-coaches .CoachStatus .Online span {
    font-weight: 500;
    color: #C58524;
}

.my-coaches .PointsContent .PointsCount h4.count-numbers {
    font-size: 14px;
    margin: 0 0 0 5px;
    font-weight: normal;
}

.my-coaches .PointsContent .PointsCount {
    border-radius: 10px;
}

.my-coaches .PointsContent .PointsCount i {
    line-height: normal;
}

.my-coaches .PointsContent .TotalPoints {
    text-align: center;
    height: 68px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-coaches .PointsContent .TotalPoints i {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}

.my-coaches .TotalPoints.LikeThumb i {
    color: #C58524;
}

.my-coaches .TotalPoints.DislikeThumb i {
    color: #960303;
}

.my-coaches .ContactsCoach {
    color: #C58524;
}

.my-coaches .MyCoachesList .BarProgress {
    width: 100%;
}

.my-coaches .MyCoachesList .BarProgress .ProgressLabel {
    width: 100%;
    float: none;
}

.my-coaches .VariousCalls.CoachProgressBars .MyCoachesList {
    min-height: 250px;
    overflow: auto;
    max-height: 250px;
}

.my-coaches .ProgressDashboard .progress,
.my-coaches .ProgressDashboardCoaching .progress {
    height: 30px;
    position: relative;
}

.my-coaches .ProgressDashboard.OutboundGoals .progress-bar.bg-primary {
    height: 25px;
    margin: 1px 0 0 -1px;
}

.toast-custom-cls {
    box-shadow: none;
    font-size: 16px;

}

.toast-custom-cls.success {
    color: #C58524;
    display: none;
}

.toast-custom-cls button.Toastify__close-button {
    display: none;
}

.toast-custom-cls.error {
    color: red;
}

.my-coaches .CoachesLists.LockCoach .card-body {
    min-height: 490px;
}

.MyCoachesList img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 50%;
}

.NewCoachDialer .CoachRevnewCount {
    background: #454444;
    border: 5px solid #d9d9d9;
    border-radius: 8px;
    min-height: 166px;
}

.NewCoachDialer .RevenueCoach {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.NewCoachDialer .CoachRevCount {
    text-align: center;
    color: #fff;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.NewCoachDialer .CoachRevCount i {
    background: #fff;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NewCoachDialer .CoachRevCount i.ion.ion-md-thumbs-up {
    color: #C58524;
}

.NewCoachDialer .CoachRevCount i.ion.ion-md-thumbs-down {
    color: #960303;
}

.NewCoachDialer .boxescard .BarProgress {
    width: 100%;
}

.NewCoachDialer .boxescard.CampaignStats.card .card-body {
    min-height: 330px;
    max-height: 330px;
}

.NewCoachDialer .boxescard.CampaignStats.card .card-body .NewDailerCampaign {
    overflow: auto;
    min-height: 230px;
    max-height: 230px;
}

.NewCoachDialer .boxescard.card .card-body .Breakdown.PathwayProgression .ProfileBreakDown {
    min-height: 295px;
    max-height: 295px;
    overflow: auto;
}

.NewCoachDialer .SectionHeader.RoiPage .Percents h4.GrayBG span.pull-left {
    font-size: 14px;
    margin: 0;
    float: left;
}

.NewCoachDialer .boxescard.ListAction .card-body {
    min-height: 326px;
    max-height: 326px;
}

.NewCoachDialer .boxescard.ListAction .card-body .todo-list {
    min-height: 240px;
    max-height: 240px;
    overflow: auto;
}

.NewCoachDialer .MonthlyDropDown select.form-control {
    width: 130px;
}

.NewCoachDialer .list-todo.activityLists .ActivityIcon {
    width: 25px;
    display: inline-block;
}

.NewCoachDialer .list-todo.activityLists .ActivityIcon img {
    width: 100%;
}

.NewCoachDialer .list-todo.activityLists p.notes {
    width: 85%;
    margin: 0 0 0 15px;
}

.NewCoachDialer .list-todo.activityLists .ActivityDate {
    margin: 0 20px 0 0px;
}

.NewCoachDialer .ActivityRecent .card-body {
    min-height: 320px;
    max-height: 320px;
}

.NewCoachDialer .ActivityRecent .card-body ul.list-group.todo-list {
    min-height: 230px;
    max-height: 230px;
    overflow: auto;
}

.Statuses.clientProfileDetail.FinacialSuccess .VariousCalls.TotalRecord.MyScheduleMain.boxescard.calanderbox.card {
    min-height: 320px;
    max-height: 320px;
}

.NewCoachDialer .ProgressDashboard {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 15px;
}

.NewCoachDialer .VariousCalls.card i.ion {
    font-size: 25px;
    width: auto;
}

.NewCoachDialer .FinacialIcon {
    width: 20px;
    float: left;
    margin: 18px 0 0 0;
}

.NewCoachDialer .FinacialIcon img {
    width: 100%;
}

.NewCoachDialer .boxescard .BarProgress i.ion.ion-md-thumbs-up {
    background: #C58524;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewCoachDialer .FinacialLists {
    min-width: 270px;
    max-height: 270px;
    overflow: auto;
}

.NewCoachDialer .PipelineLists .BarProgress .ProgressLabel {
    width: 100%;
}

.NewCoachDialer .boxescard.calanderbox.PipelineStats .card-body {
    min-height: 320px;
    max-height: 320px;
}

.NewCoachDialer .boxescard.calanderbox.PipelineStats .PipelineLists {
    min-height: 275px;
    overflow: auto;
    max-height: 275px;
}

.page-content.dialer .NewClientDialer .card.card-body {
    padding: 0 0.7rem;
}

.NewClientDialer .Breakdown.PathwayProgression .ProfileBreakDown {
    min-height: 235px;
    max-height: 235px;
}

.NewLeadDialer .VariousCalls.TotalRecord.MyScheduleMain.TaskManager .RecentRecord.MyTasks.NewTasks ul {
    max-height: 360px;
}

.NewClientDialer .TaskManager .MyScheduleMain.card ul {
    max-height: 365px;
    /*margin: 10px 0 0 0;*/
}

.NewClientDialer .ClientActionList.card-body {
    min-height: 285px;
    max-height: 285px;
}

.NewClientDialer .EventsCalendar input.form-control {
    right: 0;
    margin-right: 10px;
    margin-top: 0;
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 7px), calc(100% - 7px) calc(0.5em + 7px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    /* background-color: #fff; */
    background-color: #f1f1f1;
    height: 30px;
    position: relative;
    z-index: 1;
    border: 0;
    right: 0;
    min-width: 145px;
    max-width: 145px;
}

.NewClientDialer .EventsCalendar .calander-full-dashboard .selectboxtop.PlusSign.CalanderPanel {
    margin: 0;
}

.NewClientDialer .EventsCalendar.card-body .DayCal {
    min-height: 215px;
    max-height: 215px;
    overflow: auto;
    margin-top: -35px;
    position: relative;
    z-index: 1;
}

.NewClientDialer .EventsCalendar.card-body .DayCal ul.CalendarTask {
    padding: 0;
    list-style: none;
}

.NewClientDialer .EventsCalendar ul.CalendarTask li {
    display: flex;
    justify-content: left;
    align-items: center;
    box-shadow: 0 0px 5px 3px #eee;
    width: 95%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 0 0 0 8px;
    cursor: pointer;
}

.NewClientDialer .EventsCalendar ul.CalendarTask li .DotTask {
    margin: 0 15px 0px 0px;
}

.NewClientDialer .EventsCalendar ul.CalendarTask li .TaskDescp {
    width: 100%;
}

.NewClientDialer .EventsCalendar.card-body {
    min-height: 608px;
    max-height: 608px;
}

.NewClientDialer .InboxBusiness.ClientDailer.card-body {
    min-height: 608px;
    max-height: 608px;
}

.NewClientDialer .InboxBusiness.ClientDailer.card-body ul {
    padding: 0;
    list-style: none;
    min-height: 263px;
    max-height: 262px;
    margin: 0 0 20px 0;
    overflow: auto;
}

.NewClientDialer .InboxBusiness ul li.activity-note-in {
    display: flex;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .StatusIcon,
.NewClientDialer .InboxBusiness ul li.activity-note-in .StatusIcon {
    width: 30px;
    height: 30px;
    background: #C58524;
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: -10px 5px 0 0;
    float: left;
    line-height: 30px;
    flex-basis: 30px;
    flex-shrink: 0;
}

.NewClientDialer .InboxBusiness ul li.activity-note-in .StatusIcon {
    margin: -10px 10px 0 0px;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .ContactDescription {
    background: #454444;
    min-width: 85%;
    float: left;
    width: auto;
    max-width: 85%;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .desp-box {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    min-width: 202px;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .desp-box {
    max-width: 202px;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .reply-icon {
    float: left;
    width: 25px;
    height: 25px;
    background: #C58524;
    color: #fff;
    border-radius: 50px;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    margin: 0 0 0 5px;
}

.NewClientDialer .InboxBusiness ul li.activity-note-in .reply-icon {
    display: none;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .UserMessage p {
    font-size: 13px;
    color: #fff;
}

.NewClientDialer ul li.activity-note-out .NameUser h6.card-title {
    color: #fff;
    font-size: 13px;
}

.NewClientDialer .MessageDate {
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
    margin: 5px 0 0 0;
    display: inline-block;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out {
    padding-left: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    box-shadow: none;
}

.NewClientDialer .InboxBusiness ul li.activity-note-out .StatusIcon i,
.NewClientDialer .InboxBusiness ul li.activity-note-in .StatusIcon i {
    font-size: 15px;
}

.NewClientDialer .InboxBusiness ul li {
    /* justify-content: space-between; */
    box-shadow: 0px 0px 5px 0px #999;
    width: 95%;
    margin: 0 auto;
    border-radius: 5px;
}

.NewClientDialer .InboxBusiness ul li.activity-note-in {
    display: flex;
    margin: 0;
    height: 100%;
    align-items: center;
}

.NewClientDialer .SendMessage .form-control {
    border: 3px solid #d9d9d9;
    min-height: 65px;
    line-height: normal;
    resize: none;
    text-transform: uppercase;
    font-size: 14px;
    padding: 3px;
}

.NewClientDialer .Breakdown {
    min-height: 210px;
    max-height: 210px;
    overflow: auto;
}

.NewClientDialer .Breakdown.PathwayProgression {
    min-height: auto;
    max-height: unset;
    overflow: auto;
}

.NewClientDialer .card-title,
.NewClientDialer h5 {
    font-size: 16px;
    margin: 0;
}

.ProfileIcons.Video.disable:after {
    content: "Coming Soon";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    line-height: normal;
    cursor: auto;
    color: #fff;
    margin: auto;
    display: flex;
    align-items: center;
}

.ProfileClientActivity.Events input.form-control {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #C58524, #C58524);
    background-position: calc(100% - 12px) calc(0.5em + 2px), calc(100% - 8px) calc(0.5em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2em 2.5em;
    background-repeat: no-repeat;
    min-width: 150px;
    background-color: #f1f1f1;
    height: 25px;
    position: relative;
    z-index: 1;
    border: 0;
    left: 0px;
    float: left;
    max-width: 150px;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask {
    padding: 0;
    list-style: none;
    margin: 0;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask li {
    display: flex;
    align-items: center;
}

.ProfileClientActivity.Events .DayCal ul.CalendarTask li .DotTask {
    margin: 0 10px 0 0px;
}

/* fieldset.form-group {
    min-height: 440px;
    max-height: 440px;
    overflow: auto;
} */
.ProfileBreakDown .TaskDescp {
    width: 100%;
}

.card-body.package {
    border: 1px solid #ddd;
    border-radius: 5px;
}

.LicenseDetails .clientProfileHeading.ToolKit {
    width: 100%;
    max-width: 100%;
    border-radius: 5px 5px 0 0;
}

.LicenseDetails .TaskDescp {
    background: transparent;
    margin: 0;
    width: 100%;
}

.LicenseDetails ul {
    max-height: unset;
}

.LicenseDetails ul li:nth-child(odd) {
    background: #f1f1f1;
}

.LicenseDetails ul li {
    border: 0.5px solid #ddd;
    border-left: 0;
    border-right: 0;
}

.pr-15 {
    padding-right: 15px;
}


.pr-40 {
    padding-right: 40px;
}

.ProgressTrackerImplementation .TaskDesription-Ptracker {
    max-height: 350px;
}

.ProgressTrackerImplementation ul.list-group.todo-list.tracker-todo {
    max-height: 320px;
    min-height: 320px;
}

.ProgressTrackerImplementation .ProfileBreakDown.PTracker.ProgressTrackers {
    max-height: 400px;
    min-height: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.CompanyDetail.ClientDialer.ClientProfileDetails.NewClientDialer .NoShadow.custom-scroll.LeftSideUser.FixedLeftSidebar .AboutCard {
    height: calc(100vh - 340px);
}

.ClientProfileDetails .border-three .card {
    max-width: unset;
}

.form-control[type=color] {
    width: 100%;
}

li[disabled],
i[disabled] {
    pointer-events: none;
    opacity: 0.65;
}

table.table.table-borderless {
    border: transparent;
}

.PipeLineStatus table.table.table-borderless,
.PipeLineStatus table.table.table-borderless tr,
.PipeLineStatus table.table.table-borderless td {
    border-color: transparent;
    vertical-align: middle;
}

.PipeLineStatus table tr td:first-child {
    text-align: center;
}

.PipeLineStatus table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.PipeLineStatus table tr {
    background: #eee;
}

.TableName a {
    line-height: normal;
    height: 17px;
    float: right;
    margin: 4px 0 0 0;
}

.TableName a i.fas.fa-plus {
    font-size: 16px;
}

.TableName h4 {
    margin: 0;
}

.MainTZone.MainTime.TwoTimeZone {
    z-index: 10;
}

.DealCards {
    border: 1px solid #eee;
    border-radius: 3px;

}

.DealCards .form-check {
    background: #eee;
    width: 100%;
}

.DealCards .form-check {
    background: #eee;
    width: 100%;
    padding: 5px 10px;
    position: relative;
    border-radius: 3px 3px 0 0;
}

.DealCards input.form-check-input {
    margin: 0;
    width: 15px;
    height: 15px;
}

.DealCards .form-check-right .form-check-label {
    display: block;
    color: #000;
    font-size: 16px;
}

.DealCards .form-check.complete {
    background: #C58524;
    color: #fff;
}

.DealCards .form-check.complete label.form-check-label {
    color: #fff;
}

.DealCards .form-check.complete input.form-check-input {
    opacity: 1;
}

.cr-btns.pull-right {
    position: absolute;
    right: 55px;
    top: 41px;
    z-index: 9;
}

.training-progress .progress {
    border-radius: 50px;
    height: 22px;
}

.welcome-training {
    max-width: 350px;
    margin: 0;
    text-align: center;
    background: var(--bd-color, #454444);
    /* color: #c5cad0; */
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: auto;
    text-transform: uppercase;
}

.welcome-training h4.card-title {
    margin: 0;
}

.welcome-training {
    max-width: 425px;
    padding: 10px 0;
    top: -24px;
}

.carousel-inner .CalculatorArea {
    position: relative;
    z-index: 0;
}

/* ////////////////////////////dount chart  */

.roi-popover .fa-ul{
    margin-left: 0px;
}

.donut {
    /* (A1) CIRCLE */
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto;

    /* (A2) SEGMENTS */
    background: conic-gradient(#C58524 35%,
            #454444 20%);
}

/* (B) DONUT "CONVERSION" */
/* (B1) SMALLER CIRCLE */
.hole {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

/* (B2) "CONVERT PIE TO DONUT" - CENTER SMALLER CIRCLE */
.donut {
    display: flex;
    align-items: center;
    justify-content: center;
}


.hole p {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    z-index: 1
}

/* (C2) SEGMENTS */
.legend {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
}


.legend .legend-roi{
    font-size: 10px;
}

.legend .segment1 {
    background: #C58524;
    width: 5px;
    height: 5px;
    margin: 0 5px;
}
.legend .segment2 {
    width: 5px;
    height: 5px;
    background: #454444;
    margin: 0 5px;
}
.legend .legend-roi.total-inst {
    margin: 0 10px 0 0;
}

.d-chart .hole-arrow {
    width: 70px;
    position: absolute;
    top: auto;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
}
.d-chart .hole-arrow img {
    width: 100%;
}


button[data-command="update"]{
    display: none !important;
}

.QuestionWizred .modalQuestion button.btn.btn-info.btn-sm.ml-3 {
    position: absolute;
    right: 20px;
    background: #C58524;
    border: #C58524;
}

label.formbuilder-text-label {
    width: 95%;
}

.response-area .q-width {
    width: 96%;
}

.update-user-credential {
    position: absolute;
    bottom: 16px;
    right: 10px;
}
.goalAchivenmnetBox ul.nav.nav-tabs.nav-fill {
    display: inline-block;
    border-bottom: 0
}
.goalAchivenmnetBox ul.nav.nav-tabs.nav-fill li.nav-item {
    width: 100px;
    display: inline-block;
    margin: 0 15px 0 0px;
}
.goalAchivenmnetBox ul.nav.nav-tabs button.nav-link {
    background: var(--bd-color, #f16321);
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    border: 0;
}
.goalAchivenmnetBox ul.nav.nav-tabs button.active.nav-link{
    background: #f4bc33;
    border-color: #f4bc33;
}
.goalAchivenmnetBox .todotask .list-todo p.notes {
    width: calc(100% - 110px);
}
.goalAchivenmnetBox .TaskNumber {
    background: #021732;
    min-width: 45px;
    float: left;
    text-align: center;
    color: #fff;
    height: 80px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    margin: 10px 10px 0 10px;
}
.goalAchivenmnetBox .TaskNumber.complete {
    background: rgb(244, 188, 51);
}
.goalAchivenmnetBox .list-todo button.btn {
    margin-top: 30px;
}
.SummaryAssessment .survey .goalAchivenmnetBox.card.card-body {
    min-height: calc(100vh - 322px);
    margin: 0;
}
.goalAchivenmnetBox ul.list-group.todo-list {
    list-style: none;
    padding: 0;
    overflow: auto;
    /* min-height: 180px; */
    max-height: calc(100vh - 475px);
}

.ClientProfileGoals .goalAchivenmnetBox ul.nav.nav-tabs.nav-fill li.nav-item {
    width: 70px;
    display: inline-block;
    margin: 0 1px 0 0px;
}

.ClientProfileGoals .goalAchivenmnetBox ul.nav.nav-tabs button.nav-link{
    padding: 5px 3px !important;
    font-size: 10px;
}
.ClientProfileGoals .goalAchivenmnetBox .list-todo button {
    width: 25px;
    height: 25px;
    font-size: 10px;
    line-height: 23px;
}
/*.ClientProfileGoals .goalAchivenmnetBox .btn {
    padding: 0;
    text-align: center;
    
}*/
.ClientProfileGoals .goalAchivenmnetBox .btn i{
    font-size: 14px !important;
}
.ClientProfileGoals .goalAchivenmnetBox .TaskNumber {
    background: #021732;
    min-width: 10px;
    float: left;
    text-align: center;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0px 0 0 5px;
}
.ClientProfileGoals .goalAchivenmnetBox button.btn.btn-link.Remove-Action {
    background: #f1f1f1 !important;
    border-radius: 50px;
    color: #860606;
    flex-shrink: 0;
    justify-content: center;
    padding: 0;
}
.ClientProfileGoals .goalAchivenmnetBox .todotask .list-todo p.notes {
    width: calc(100% - 65px);
    font-size: 10px;
}
.ClientProfileGoals ul.todo-list li button.btn {
    height: 25px;
    width: 25px;
    float: left;
    margin: 0;
}
.ClientProfileGoals .MonthlyDropDown select {
    width: 110px;
}
.ClientProfileGoals .goalAchivenmnetBox .todotask .list-todo p.notes hr {
    margin: 0.1rem 0;
}
.ClientProfileGoals .goalAchivenmnetBox ul.list-group.todo-list {
    min-height: auto;
    max-height: 115px;
}

.subject .active {
    background: #f2f1f9;
}

.subject a.dropdown-item {
    padding: 0.35rem 1.5rem;
}

.subject a.dropdown-item:hover, .subject a.dropdown-item:focus {
    color: #2c3034;
    background-color: #f8f9fa;
    text-decoration: none !important;
}

.document-drag-handle {
    color: #d9e2ec;
    cursor: move;
    margin-right: 4px;
}

.main-way .row {
    display: flex;
}

#record-section canvas.audio-react-recorder__canvas {
    height: 100px !important;
}
.field-is-updated {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-color: #13b716;
}

/* .ProposalFooter .logo {width: 300px;float: left;margin: 50px 0 0 50px;} */
/* .ProposalFooter {background: url('https://ws.profitcoach.app/crmdoc/default/images/assessment_proposal/footer-ribben.png') no-repeat;width: 100%;height: 190px;background-size: cover;margin: 50px 0 60px 0;display: flex;align-items: center;} */
/* .ProposalFooter .logo {margin: 50px 0 0 10px;} */
/* .ProposalFooter .logo img {width: 100%;margin-top: 20px;} */
/* .PageNumber {color: #fff;float: right;height: 144px;position: relative;top: 10px;display: flex;align-items: center;} */
/* .PageNumber h3 {font-size: 30px;} */

